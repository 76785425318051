import React from "react";

const Index = () => {
    return (
        <div className="upcomecnt_list">
            <div className="upcomecnt_list_item">
                <div class="upcomecnt_list_item-card">
                    <div class="additional">
                        <div class="user-card">
                            <div class="upcomecnt_list_item_count">8</div>
                        </div>
                    </div>
                    <div class="general">
                        <h6>Life Contingency Tutorials</h6>
                        <p>
                            8th - 10th July 2021
                            <svg
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="3" cy="3" r="3" fill="#888888" />
                            </svg>
                            8 A.M - 9 A.M
                        </p>
                        {/* <p>Edulog Tutorial College, Blk 56, Lagos State.</p> */}
                    </div>
                </div>
            </div>
            <div className="upcomecnt_list_item">
                <div class="upcomecnt_list_item-card">
                    <div class="additional">
                        <div class="user-card">
                            <div class="upcomecnt_list_item_count">8</div>
                        </div>
                    </div>
                    <div class="general">
                        <h6>Life Contingency Tutorials</h6>
                        <p>
                            8th - 10th July 2021
                            <svg
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="3" cy="3" r="3" fill="#888888" />
                            </svg>
                            8 A.M - 9 A.M
                        </p>
                        {/* <p>Edulog Tutorial College, Blk 56, Lagos State.</p> */}
                    </div>
                </div>
            </div>
            <div className="upcomecnt_list_item">
                <div class="upcomecnt_list_item-card">
                    <div class="additional">
                        <div class="user-card">
                            <div class="upcomecnt_list_item_count">18</div>
                        </div>
                    </div>
                    <div class="general">
                        <h6>Life Contingency Tutorials</h6>
                        <p>
                            18th - 10th July 2021
                            <svg
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="3" cy="3" r="3" fill="#888888" />
                            </svg>
                            8 A.M - 9 A.M
                        </p>
                        {/* <p>Edulog Tutorial College, Blk 56, Lagos State.</p> */}
                    </div>
                </div>
            </div>
            <div className="upcomecnt_list_item">
                <div class="upcomecnt_list_item-card">
                    <div class="additional">
                        <div class="user-card">
                            <div class="upcomecnt_list_item_count">8</div>
                        </div>
                    </div>
                    <div class="general">
                        <h6>Life Contingency Tutorials</h6>
                        <p>
                            8th - 10th July 2021
                            <svg
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="3" cy="3" r="3" fill="#888888" />
                            </svg>
                            8 A.M - 9 A.M
                        </p>
                        {/* <p>Edulog Tutorial College, Blk 56, Lagos State.</p> */}
                    </div>
                </div>
            </div>
            <div className="upcomecnt_list_item">
                <div class="upcomecnt_list_item-card">
                    <div class="additional">
                        <div class="user-card">
                            <div class="upcomecnt_list_item_count">18</div>
                        </div>
                    </div>
                    <div class="general">
                        <h6>Life Contingency Tutorials</h6>
                        <p>
                            18th - 10th July 2021
                            <svg
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="3" cy="3" r="3" fill="#888888" />
                            </svg>
                            8 A.M - 9 A.M
                        </p>
                        {/* <p>Edulog Tutorial College, Blk 56, Lagos State.</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
