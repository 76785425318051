
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField } from '@mui/material';
import { DateTimePicker, } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as Yup from "yup";
import CustomAlert from "../../../components/CustomAlert";
import Loader from "../../../components/Loader";
import { listScheduledEvents, rescheduleEvent, rescheduleEventErrorStatus, rescheduleEventStatus, reSchedulePaidEvent, listSwitchEvent, clearMessage } from "../../../redux/actions";
import Tags from '../SelectableTags';
import "./reschedule.less";
// import { convertToUTCString, convertUtcToTimezone } from "../../../utils/CommonUtils";
import dayjs from "dayjs";


const validation = Yup.object({
    Selectdate: Yup.date().required('*Required').nullable(),
    Location: Yup.string().required("*Required").nullable(),
    password: Yup.string().max(20, "Password only allows 20 characters").nullable(),
    Description: Yup.string().required('*Required').max(250, 'Description only allows 250 characters').nullable(),
})

const Index = (props) => {
    const [loader, setloader] = useState(false)
    const [selectedTimesheet, setSelectedTimesheet] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [isTagError, setIsTagError] = useState(false);
    const [selectedAdvisor, setSelectedAdvisor] = useState(null);
    const [AdvisorId, setAdvisorId] = useState("");
    const [EventId, setEventId] = useState("");
    const [splitedTime, setSplitedTime] = useState([]);
    const [minDate, setMinDate] = useState(dayjs());

    const { register, handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors, } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    const Description = watch("Description")

    const handleTagClick = (tag) => {
        setSelectedTag(tag);
        setIsTagError(false);
    };

    const formatTime = (timeStr) => {
        const date = new Date(timeStr);
        const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
        return formattedTime;
    };

    useEffect(() => {
        setSelectedTimesheet([])
        setloader(false)
        reset()
        props?.clearMessage()
    }, [])


    useEffect(() => {
        if (props?.Data && props?.Data?.length > 0) {
            const { Starttime, description, meeting, Endtime, meetingPassword } = props?.Data[0];
            setValue('Selectdate', Starttime);
            setValue('Location', meeting);
            setValue('password', props?.Data[0]?.Password);
            setEventId(props?.Data[0]?.EventId);
        }
        if(props?.advisorData){
            TimeSplitter()
        }
        if (props?.userProfile?.timezone) {
            updateMinDate();
        }
    }, [props?.Data,props?.advisorData,props?.userProfile?.timezone]);

    const handleClose = () => {
        reset()
        setSelectedTimesheet([])
    };
    function convertTo24HourFormat(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:00`;
    }
    const onSubmit = (data) => {
        const moment = require('moment');
        if (!selectedTag) {
            setIsTagError(true);
            return;
        }
        setloader(true)
        const SelectHours = selectedTag
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        const startTime = SelectHours?.split(' - ')[0];
        let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)

        const date = new Date(data?.Selectdate);
        // Time Gap
        const [startTimes, endTime] = selectedTag?.split(' - ');
        let [hoursStr, minutesStr] = startTimes.split(':');
        let hours = parseInt(hoursStr, 10);
        let minutes = parseInt(minutesStr, 10);
        if (startTimes.includes('PM') && hours !== 12) {
            hours += 12;
        } else if (startTimes.includes('AM') && hours === 12) {
            hours = 0;
        }
        date.setHours(hours);
        date.setMinutes(parseInt(minutes));
        const parsedStartTime = moment(startTimes, 'h:mm A');
        const parsedEndTime = moment(endTime, 'h:mm A');
        const timeDiffInMillis = parsedEndTime?.diff(parsedStartTime);
        const timeDiffInMinutes = moment?.duration(timeDiffInMillis).asMinutes();
        let currDate = new Date(data?.Selectdate)


        const inviteData = props?.Data[0]?.Invitation.map(obj => obj.userId);
        const childArray = props?.childList?.map(obj => obj.id);
        // Find matched IDs
        const matchedIds = inviteData?.filter(id => childArray?.includes(id));
        // Filter out unmatched IDs
        const matchedChild = props?.Data[0]?.Invitation?.filter(obj => matchedIds?.includes(obj?.userId));
        const startHours = data?.startTime?.getHours();
        const startMinutes = data?.startTime?.getMinutes();
        const startSeconds = data?.startTime?.getSeconds();

        data?.Selectdate?.setHours(startHours);
        data?.Selectdate?.setMinutes(startMinutes);
        data?.Selectdate?.setSeconds(startSeconds);

        let payload = {
            "eventId": props?.Data && props?.Data[0]?.EventId,
            "eventTime": currentTime,
            "eventDuration": 30 ,
            "meetingLocation": data?.Location,
            "reason": data?.Description,
            "password": data?.password
        }
        if (props?.parent === true) {
            payload["studentId"] = matchedChild && matchedChild[0]?.userId;
        }
        console.log(payload, "payload");
        props?.rescheduleEvent(payload)

        setloader(false)
        props?.DetailClose();
    }

    const selectedDate = watch("Selectdate");

    useEffect(() => {
        if (selectedDate && splitedTime) {
            handleDateChange(selectedDate);
        }
    }, [selectedDate,splitedTime]);

    const updateMinDate = () => {
        const now = dayjs();
        const tzDate = now.tz(props?.userProfile?.timezone).startOf('day');
        setMinDate(tzDate);
    };

    useEffect(() => {
        if (props?.Data && props?.Data[0]?.AdvisorData?.length > 0 && props?.Data[0]?.Invitation?.length > 0) {
            if (props?.parent) {
                const advisorData = props?.Data[0]?.AdvisorData.map(obj => obj.id);
                const childArray = props?.childList.map(obj => obj.id);
                // Find matched IDs
                const matchedIds = advisorData.filter(id => childArray.includes(id));
                // Filter out unmatched IDs
                const matchedAdvisor = props?.Data[0]?.AdvisorData.filter(obj => !matchedIds.includes(obj.id));
                setSelectedAdvisor(matchedAdvisor)
                setAdvisorId(matchedAdvisor?.id)

            }
            else {
                if (props?.Data && props?.Data[0]?.AdvisorData?.length > 0 && props?.Data[0]?.Invitation?.length > 0 && props?.connectedparent?.length > 0) {
                    const advisorData = props?.Data[0]?.AdvisorData.map(obj => obj.id);
                    const Parentarray = props?.connectedparent?.map(obj => obj.id);
                    const matchedIds = advisorData.filter(id => Parentarray.includes(id));
                    // Filter out unmatched IDs
                    const matchedAdvisor = props?.Data[0]?.AdvisorData.filter(obj => !matchedIds.includes(obj.id));
                    setSelectedAdvisor(matchedAdvisor)
                    setAdvisorId(matchedAdvisor?.id)
                }
                else {
                    let a = props?.Data[0]?.Invitation.forEach(invite => {
                        props?.Data[0]?.AdvisorData.forEach((advisor) => {
                            if (invite.userId === advisor.id) {
                                setSelectedAdvisor(advisor)
                                setAdvisorId(advisor?.id)
                            }
                        })
                    });
                }
            }
        }
    }, [props?.Data])

    const TimeSplitter = () => {
        if (props?.advisorData?.advisorWorkingHoursWithDay ) {
            const advisorWorkingHours = props?.advisorData?.advisorWorkingHoursWithDay;

            const splitTimesheets = {
                "mondayTimesheet": [],
                "tuesdayTimesheet": [],
                "wednesdayTimesheet": [],
                "thursdayTimesheet": [],
                "fridayTimesheet": [],
                "saturdayTimesheet": [],
                "sundayTimesheet": []
            };

            const dayMapping = {
                "Monday": "mondayTimesheet",
                "Tuesday": "tuesdayTimesheet",
                "Wednesday": "wednesdayTimesheet",
                "Thursday": "thursdayTimesheet",
                "Friday": "fridayTimesheet",
                "Saturday": "saturdayTimesheet",
                "Sunday": "sundayTimesheet",
                "Mon": "mondayTimesheet",
                "Tue": "tuesdayTimesheet",
                "Wed": "wednesdayTimesheet",
                "Thu": "thursdayTimesheet",
                "Fri": "fridayTimesheet",
                "Sat": "saturdayTimesheet",
                "Sun": "sundayTimesheet"
            };

            function parseTimeAndDay(timeAndDay) {
                const lastSpaceIndex = timeAndDay?.lastIndexOf(' ');
                const time = timeAndDay?.substring(0, lastSpaceIndex).trim();
                const day = timeAndDay?.substring(lastSpaceIndex + 1).trim();
                return [time, day];
            }

            const convertTimeFormat = (convertedTime) => {
                const [time, day] = convertedTime?.split(' ');
                const converted12HourTime = dayjs(time, 'HH:mm')?.format('hh:mm A');
                return `${converted12HourTime} ${day}`;
            };

            Object.keys(advisorWorkingHours)?.forEach(day => {
                const dayTimeSheet = advisorWorkingHours[day];

                if (Array.isArray(dayTimeSheet) && dayTimeSheet?.length > 0) {
                    dayTimeSheet.forEach(entry => {

                        const convertedStartTime = convertTimeFormat(entry?.startingTimeday);
                        const convertedCloseTime = convertTimeFormat(entry?.closingTimeday);

                        if (convertedStartTime && convertedCloseTime) {
                            const [startTime, startDay] = parseTimeAndDay(convertedStartTime);
                            const [endTime, endDay] = parseTimeAndDay(convertedCloseTime);

                            const startDayKey = dayMapping[startDay];
                            const endDayKey = dayMapping[endDay];

                            if (!startDayKey || !endDayKey) {
                                console.error(`Invalid day key mapping: startDay=${startDay}, endDay=${endDay}`);
                                return;
                            }

                            if (startDayKey === endDayKey) {
                                // Case 1: Same day
                                console.log("Case 1: Same day");
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            } else {
                                // Case 2: Different days
                                console.log("Case 2: Different days");
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `12:00 AM, ${startDay}`
                                });

                                splitTimesheets[endDayKey].push({
                                    startingTime: `12:00 AM, ${endDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            }
                        }

                    });
                }
            });
            if (splitTimesheets) {
                const updatedTimesheets = { ...splitTimesheets };
                Object.keys(updatedTimesheets).forEach(day => {
                    if (updatedTimesheets[day]?.length > 0 && updatedTimesheets[day][0]?.startingTime?.includes('PM')) {
                        const pmEntry = updatedTimesheets[day]?.shift();
                        updatedTimesheets[day]?.push(pmEntry);
                    }
                });
                console.log("splitTimesheets", updatedTimesheets);
                setSplitedTime(updatedTimesheets);
            }
        }
    }
    const handleDateChange = (date) => {
        let sessionLength = props?.duration || 30;
        if (date && splitedTime) {
            const selectedDay = dayjs(date)?.format("dddd").toLowerCase();
            const timesheetArray = splitedTime[`${selectedDay}Timesheet`];
            const timeIntervals = new Set();

            timesheetArray?.forEach(entry => {
                let startTime = dayjs(entry?.startingTime, 'hh:mm A, dddd');
                let endTime = dayjs(entry?.closingTime, 'hh:mm A, dddd');

                if (endTime?.isBefore(startTime)) {
                    endTime = endTime?.add(1, 'day');
                }

                while (startTime?.isBefore(endTime)) {
                    const intervalEnd = dayjs(Math?.min(startTime.clone()?.add(sessionLength, 'minutes')?.valueOf(), endTime?.valueOf()));
                    const intervalText = startTime?.format('h:mm A') + ' - ' + intervalEnd?.format('h:mm A');
                    timeIntervals?.add(intervalText);

                    startTime = startTime?.add(sessionLength, 'minutes');
                }
            });
            const uniqueTimeIntervals = Array?.from(timeIntervals)?.map(interval => ({
                text: interval,
                isClickable: true
            }));
            setSelectedTimesheet(uniqueTimeIntervals);
        }
    };



    useEffect(() => {
        if (props?.reScheduledEvents) {
            props?.onHide()
            setSelectedTimesheet([])
            handleClose()
            setloader(false)
            reset()
            props?.rescheduleEventStatus(false)
            if (props?.userProfile?.currentRole === "parent") {
                props.listSwitchEvent(localStorage.getItem("parentViaChild"))
            } else {
                props?.listScheduledEvents()
            }
        }
        if (props?.reScheduledError) {
            setSelectedTimesheet([])
            setloader(false)
            reset()
            props?.rescheduleEventErrorStatus(false)
        }

    }, [props?.reScheduledEvents, props?.reScheduledError])


    return (
        <>
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Reschedule Session
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    {loader && <Loader />}
                    <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", "paddingTop": "5px" }}> {<CustomAlert />}</div>
                    <form id="" onSubmit={handleSubmit(onSubmit)} >
                        <Grid container>
                            <Grid item xs={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <textarea {...register("Description")} refs="Description" maxLength={250} className={"textarea--primary textbox--rounded input"} name="Description"
                                        placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" />
                                    <label >Reason for Reschedule</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Description?.message}</div>
                                    <div style={{ "color": "#595959", fontSize: "12px", float: 'right' }} >{Description?.length ? Description?.length : 0}/250 </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    {/* <input ref="Selectdate" type="date" className="textbox--primary textbox--rounded input"
                                        name="Selectdate" placeholder="Selectdate" {...register('Selectdate')}
                                    /> */}
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Controller
                                            name="Selectdate"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <DateTimePicker
                                                    label="Select Date"
                                                    value={field.value}
                                                    views={['year', 'month', 'day']}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(date) => {
                                                        if (date instanceof Date && !isNaN(date.getTime())) {
                                                            field.onChange(date);
                                                        }
                                                    }}
                                                    minDate={minDate}
                                                    renderInput={(params) => <TextField {...params}
                                                        sx={{
                                                            width: '100%',
                                                            '& .MuiOutlinedInput-root': {
                                                                border: 'none', // Remove the border

                                                                '&:hover fieldset': {
                                                                    border: 'none', // Remove hover border

                                                                },
                                                            },
                                                        }}
                                                    />}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>


                                    <label>Selectdate</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Selectdate?.message}</div>
                                    <label>Selectdate</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Selectdate?.message}</div>
                                </div>

                            </Grid>
                        </Grid>
                        {selectedTimesheet && selectedTimesheet.length > 0 && (
                            <>
                                <p>Available Time</p>
                                <div style={{ "display": "flex", "flexWrap": "wrap", "marginBottom": "20px", "height": "150px", "overflow": "scroll" }}>
                                {selectedTimesheet?.map(time => (
                                    <Tags
                                        key={time.text}
                                        bold
                                        text={time.text}
                                        onTagClick={time.isClickable ? handleTagClick : null} // Only add onClick handler if clickable
                                        isSelected={selectedTag === time.text}
                                        isClickable={time.isClickable} // Pass the clickable state to the tag component
                                    />
                                ))}
                                </div>
                            </>
                        )}
                        {isTagError && <p style={{ color: '#E64A19', fontSize: '12px' }}>Please select a Time</p>}
                        {selectedDate && (!selectedTimesheet || selectedTimesheet.length === 0) && (
                            <>
                                {/* <div style={{ "display": "flex", "flexWrap": "wrap", "marginBottom": "20px" }}>
                                    <p style={{ "marginBottom": "0px","color":"#E64A19" }} >Advisor not available for this date</p>
                                </div> */}
                                <div className="emptyState, zeroStateReceived mb-5" style={{ "display": "flex", "justifyContent": "center", }} >
                                    <div className="" style={{ "border": "1px solid #cdcdcd", padding: "20px", "borderRadius": "10px" }}>
                                        <div className="Empty_Card emptyShadow" style={{ "boxShadow": 'none' }} >
                                            <p className='mb-0' style={{ "color": "#E64A19" }}>Advisor not available for this date</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <Grid container>
                            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <input type="text" className="textbox--primary textbox--rounded input"
                                        placeholder="Location" {...register('Location')}
                                    />
                                    <label>Meeting Location</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Location?.message}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <input refs="password" type="text" className="textbox--primary textbox--rounded input"
                                        placeholder="Password" {...register('password')}
                                    />
                                    <label>Meeting Password</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.password?.message}</div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sx={{ width: '100%' }}>
                                <div className="BookSessionModal_footer">
                                    <Button variant="contained" className="BookBtn" type="submit">Re Schedule</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const { reScheduledEvents, reScheduledError } = commonData;
    const { childprofile, userProfile } = userProfileData;
    const { connectedparent } = studentData;
    return { reScheduledEvents, reScheduledError, connectedparent, userProfile };
};

export default connect(mapStateToProps, { listScheduledEvents, rescheduleEvent, rescheduleEventStatus, listSwitchEvent, reSchedulePaidEvent, rescheduleEventErrorStatus, clearMessage })(Index);

