import React, { useState } from "react";
import "./profile.less"
import { Button, Modal, Grid, Autocomplete, FormControlLabel, Checkbox, Typography, Tooltip, IconButton } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidMount, useComponentDidUpdate } from "../../../utils/useEffectHooks";
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, GENDER_LABEL, GPA_LABEL, GRADUATION_YEAR_LABEL, LAST_NAME_LABEL, ZIP_CODE_LABEL, EMAIL_LABEL } from "../../../Constants/commonLabels";
import { schoolApi, universityApi } from "../../../utils/AutoComplete";
import { connect } from "react-redux";
import { setChildProfile, editChildProfile, setIsChildProfileSuccess, clearErrorList, getGender, editIsChildProfileError, editIsChildProfileSuccess, setIsChildProfileError } from "../../../redux/actions";
import Loader from "../../../components/Loader"
import CloseIcon from '@mui/icons-material/Close';
import { loadZipcode } from "../../../utils/AutoComplete";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';


const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    padding: '0',
    borderRadius: '4px',
    width: '100%',
    height: "100%",
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '50px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 40px',
    boxSizing: 'border-box',
    paddingBottom: '20px',

  },
  content: {
    overflow: 'auto',
    padding: '20px',
    flexGrow: 1,
  },
  footer: {
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginTop: '1rem'
  },
};

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
      {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
    </ValueContainer>
  );
};

const timezonesOptions = [
  { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
  { label: 'Alaska Time', value: 'America/Anchorage' },
  { label: 'Pacific Time', value: 'America/Los_Angeles' },
  { label: 'Mountain Daylight Time', value: 'America/Denver' },
  { label: 'Mountain Standard Time', value: 'America/Phoenix' },
  { label: 'Central Time', value: 'America/Chicago' },
  { label: 'Eastern Time', value: 'America/New_York' },
];

const validation = Yup.object({
  firstName: Yup.string().required("* Required").nullable().max(50, " Max upto 50 characters").min(2, "First name must contain 2 characters").matches(/^[\s]*[^\s]+([ \t]+[^\s]+)*[\s]*$/, "Field Cannot be empty spaces"),
  lastName: Yup.string().required("* Required").nullable().max(50, " Max upto 50 characters").min(1).matches(/^[\s]*[^\s]+([ \t]+[^\s]+)*[\s]*$/, "Field Cannot be empty spaces"),
  country: Yup.object().required("* Required").nullable(),
  gender: Yup.object().required("* Required").nullable(),
  mystatus: Yup.object().required("* Required").nullable(),
  year: Yup.object().required("* Required").nullable(),

  currentGpa: Yup.number().required("* Required")
    .positive().transform(value => (isNaN(value) ? undefined : value))
    .test(
      "is-decimal",
      "Invalid Weighted Gpa",
      (val) => {
        if (val != undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    )
    .min(0, "minimum 1").max(5, "maximum 5"),
  unWeighted: Yup.number().required("* Required")
    .positive().transform(value => (isNaN(value) ? undefined : value))
    .test(
      "is-decimal",
      "Invalid Gpa",
      (val) => {
        if (val != undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    )
    .min(0, "minimum 1").max(4, "maximum 4"),
  email: Yup.string()
    .required("* Required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  zipCode: Yup.string().required("* Required").when('country', {
    is: (country) => !country,
    then: Yup.string().required("*Required"),
    otherwise: Yup.string().when('country.isoCode2Digit', {
      is: 'US',
      then: Yup.string().matches(/^\d{5}$/, 'Must contain only 5 digits').required("*Required"),
      otherwise: Yup.string().matches(/^\d{6}$/, 'Must contain only 6 digits').required("*Required")
    })
  }),

  // zipCode: Yup.object({
  //   label: Yup.string().required("* Required"),
  //   value: Yup.string().required("* Required").matches(/^\d{5}$|^\d{6}$/, 'Must contain only 5 or 6 digits'),
  // }).nullable().required("* Required"),
  
  checkboxes: Yup.object({
    studentsCollab: Yup.boolean(),
    counselingAllowed: Yup.boolean(),
    helpwith: Yup.boolean(),
    subCouncelingServices: Yup.boolean(),
  }).test('at-least-one-checked', 'At least one option must be selected', value => {
    return value && Object.values(value).some(Boolean);
  }),
});



const Index = (props) => {
  const [collab, setCollab] = useState(false);
  const [advisorCounsil, setAdvisorCounsil] = useState(false);
  const [professionalCounsil, setProfessionalCounsil] = useState();
  const [helpwith, setHelpWith] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({})
  const [selectSchoolOption, setselectSchoolOption] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [highSchoolData, setHighSchoolData] = useState(null)
  const [isload, setIsload] = useState(false);
  const [loading, setloading] = useState(false)
  const [Graduationyear, setGraduationyear] = useState();
  const [GenderOption, setGenderOption] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [zipCodeOption, setZipCodeOption] = useState([])
  const [loadZipcodes, setLoadZipcodes] = useState(false);


  const { register, handleSubmit, watch, reset, setValue, clearErrors, control, formState: { errors } } = useForm({ mode: "onSubmit", resolver: yupResolver(validation), });

  const handleClosee = () => {
    props?.handleChildClose()
    clearErrors()
    if (props?.data?.helpWithCoursesAndActivities === false) {
      setAdvisorCounsil(false)
    }
    if (props?.data?.studentsCollab === false) {
      setCollab(false)
    }
    if (props?.data?.subCouncellingServices === false) {
      setFieldErrors({})
      setProfessionalCounsil(false)
    }
    if (props?.data?.helpWithMilestones === false) {
      setHelpWith(false)
    }

    props?.clearErrorList()
    setErrorList([])
    if (props?.createGoal) {
      reset()
      clearErrors()
      setselectSchoolOption(undefined)
      // setAdvisorCounsil(false)
      // setProfessionalCounsil(false)
      // setCollab(false)
      setFieldErrors({})
      setHighSchoolData(null)
      setErrorList([])
      props?.clearErrorList()
      // setHelpWith(false)
    }
  };

  console.log(GenderOption, "GenderOption")

  const handleCloseBothPopup = () => {
    props?.handleBoth()
    props?.clearErrorList()
    reset()
    clearErrors()
    setselectSchoolOption(undefined)
    setAdvisorCounsil(false)
    setProfessionalCounsil(false)
    setCollab(false)
    setFieldErrors({})
    setHighSchoolData(null)
    setErrorList([])
    setHelpWith(false)
  };

  const onValidationChange = (value) => {
    if (props?.errorList) {
      setErrorList([])
      props?.clearErrorList()
    }
    if (value === "firstName") {
      clearErrors("firstName")
    }
    if (value === "lastName") {
      clearErrors("lastName")
    }
    if (value === "email") {
      clearErrors("email")
    }
  }

  const filterCountryObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options?.filter(
        (options) => options?.isoCode2Digit?.toString() === value?.toString()
      );
    setValue(title, filtered && filtered[0]);
  };

  const filterObjectTimeZone = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options?.filter(
        (options) => options?.value?.toString() === value?.toString()
      );
    console.log(filtered[0], "filtered")
    if (filtered[0]) {

      setValue(title, filtered[0]);
    } else {
      setValue(title, { label: value, value: value })
    }
  };

  useComponentDidMount(() => {
    props.getGender();
    setButtonClicked(false)
    props?.clearErrorList()
    props?.editIsChildProfileSuccess(false)
    setValue("firstName", props?.data?.firstName);
    setValue("lastName", props?.data?.lastName);
    setValue("zipCode", props?.data?.zipCode);
    filterCountryObject("country", props?.countryOption, props?.data?.country);
    setValue("highSchool", props?.data?.studentProfile?.currrentSchoolName);
    setValue("currentGpa", props?.data?.studentProfile?.weighted);
    setValue("unWeighted", props?.data?.studentProfile?.unweighted);
    setValue("email", props?.data?.email);
    setAdvisorCounsil(props?.data?.studentProfile?.helpWithCoursesAndActivities)
    setProfessionalCounsil(props?.data?.studentProfile?.subCouncellingServices)
    setCollab(props?.data?.studentProfile?.studentsCollab)
    setselectSchoolOption(props?.data?.studentProfile?.currrentSchoolName)
    props?.setIsChildProfileSuccess(false)
    setHelpWith(props?.data?.studentProfile?.helpWithMilestones)
    filterObjectTimeZone("timeZone", timezonesOptions, props?.data?.timezone);
  }, [props]);

  useComponentDidUpdate(() => {
    if (props?.gender && props?.graduationyear) {
      setInitialValues()
    }

  }, [props?.gender, props?.graduationyear,])
  useComponentDidUpdate(() => {
    if (props?.data) {
      setValue("firstName", props?.data?.firstName);
      setValue("lastName", props?.data?.lastName);
      setValue("zipCode", props?.data?.zipCode);
      filterCountryObject("country", props?.countryOption, props?.data?.country);
      setValue("highSchool", props?.data?.studentProfile?.currrentSchoolName);
      setValue("currentGpa", props?.data?.studentProfile?.weighted);
      setValue("unWeighted", props?.data?.studentProfile?.unweighted);
      setValue("email", props?.data?.email);
      setAdvisorCounsil(props?.data?.studentProfile?.helpWithCoursesAndActivities)
      setProfessionalCounsil(props?.data?.studentProfile?.subCouncellingServices)
      setCollab(props?.data?.studentProfile?.studentsCollab)
      setselectSchoolOption(props?.data?.studentProfile?.currrentSchoolName)
      setHelpWith(props?.data?.studentProfile?.helpWithMilestones)
      filterObjectTimeZone("timeZone", timezonesOptions, props?.data?.timezone);

    }

    let genderOptions = [];
    let graduationOptions = [];
    let statusOptions = [];
    genderOptions = props?.gender?.map((genderItem) => { return { label: genderItem, value: genderItem } }
    ).find((x) => x.value === props?.data?.gender)

    graduationOptions = props?.graduationyear?.map((gradItem) => { return { label: gradItem, value: gradItem } }
    ).find((x) => x.value === props?.data?.studentProfile?.graduationYear)

    statusOptions = props?.status?.map((statusItem) => { return { label: statusItem?.status, value: statusItem?.status, role: statusItem?.role } }
    ).find((x) => x.value === props?.data?.studentProfile?.myStatus)

    setValue('gender', genderOptions)
    setValue('year', graduationOptions)
    setValue('mystatus', statusOptions)
  }, [props?.data, props?.gender]);

  const setInitialValues = () => {
    console.log(props?.gender, "props?.gender")
    let genderOptions = [];
    let graduationOptions = [];
    let statusOptions = [];
    props?.gender !== undefined && props?.gender?.length > 0 && props?.gender?.map((x, key) => {
      let temp = { label: x, value: x }
      genderOptions.push(temp);
    });
    setGenderOption(genderOptions)

    props?.graduationyear !== undefined && props?.graduationyear?.length > 0 && props?.graduationyear?.map((x, key) => {
      let temp = { label: x, value: x }
      graduationOptions.push(temp);
      setGraduationyear(graduationOptions)
    })

    genderOptions = props?.gender?.map((genderItem) => { return { label: genderItem, value: genderItem } }
    ).find((x) => x.value === props?.data?.gender)

    graduationOptions = props?.graduationyear?.map((gradItem) => { return { label: gradItem, value: gradItem } }
    ).find((x) => x.value === props?.data?.graduationYear)

    props?.status !== undefined && props?.status.length > 0 && props?.status.filter((x) => x.role !== "professional").map((x, key) => {
      let temp = { label: x.status, value: x.id, role: x.role }
      statusOptions.push(temp);
      setStatusList(statusOptions)
    });

    setValue('gender', genderOptions)
    setValue('year', graduationOptions)
  }

  useComponentDidUpdate(() => {
    if (props?.isChildProfileSucess) {
      props?.setIsChildProfileSuccess(false)
     
      reset()
      handleCloseBothPopup()
      props?.handleChildClose()
      setloading(false)
      setselectSchoolOption([])
      setButtonClicked(false)
    }
  }, [props?.isChildProfileSucess]);

  useComponentDidUpdate(() => {
    if (props?.errorList) {
      setErrorList(props?.errorList)
      setButtonClicked(false)
    }
  }, [props?.errorList]);

  useComponentDidUpdate(() => {
    if (props?.editChildProfileSucess === true) {
      localStorage.setItem("childName", props?.childprofile[0]?.fullName);
      props?.editIsChildProfileSuccess(false)
      handleCloseBothPopup()
      reset()
      setloading(false)
      setButtonClicked(false)
    }
  }, [props?.editChildProfileSucess, props?.childprofile]);

  useComponentDidUpdate(() => {
    if (props?.isCreateChildError) {
      props?.setIsChildProfileError(false)
      setloading(false)
      setButtonClicked(false)
    }
  }, [props?.isCreateChildError]);

  useComponentDidUpdate(() => {
    if (props?.isEditChildError) {
      props?.editIsChildProfileError(false)
      setloading(false)
      props?.handleBoth()
      setButtonClicked(false)
    }
  }, [props?.isEditChildError]);

  const handleValidation = () => {
    if (!selectSchoolOption) {
      let newfieldErrors = { ['highSchoolName']: "*Required" }
      setFieldErrors(newfieldErrors)
      return false
    }
    return true
  }

  const onSubmit = (data) => {
    setButtonClicked(true)
    let payload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      country: data?.country?.isoCode2Digit,
      zipCode: data?.zipCode,
      gender: data?.gender?.label,
      status: data?.mystatus?.label,
      graduationYear: data?.year?.label,
      weighted: data?.currentGpa || null,
      unweighted: data?.unWeighted || null,
      highSchoolName: selectSchoolOption,
      counselingAllowed: false,
      helpWithCoursesAndActivities: advisorCounsil === true ? true : false,
      studentsCollab: collab === true ? true : false,
      subCouncelingServices: professionalCounsil === true ? true : false,
      helpWithMilestones: helpwith === true ? true : false,
      timeZone: data?.timeZone?.value
    }
    if (handleValidation()) {
      if (props?.data?.id) {
        payload["studentId"] = props?.data?.id;
        props.editChildProfile(payload);
        setloading(true)
      } else {
        props.setChildProfile(payload, "profile")
        setloading(true)
      }
    }
  };

  const loadOptions = (value, callback) => {
    if (value === "") {
      setHighSchoolData([]);
    }
    setIsload((isload) => !isload)
    let list = []
    if (value) {
      schoolApi(value).then((res) => {
        res?.data?.data?.map((item, index) => {
          list.push({
            label: item.school,
            value: item.school
          });
        });
        setIsload((isload) => !isload)
      })
    }
    setHighSchoolData(list);
  }

  const loadUniversityOptions = (value, callback) => {
    if (value === "") {
      setHighSchoolData([]);
    }
    setIsload((isload) => !isload)
    let list = []
    if (value) {
      universityApi(value).then((res) => {
        res?.data?.data?.map((item, index) => {
          list.push({
            label: item.university,
            value: item.university
          });
        });
        setIsload((isload) => !isload)
      })
    }
    setHighSchoolData(list);
  }

  const onTagsChange = (event, values) => {
    setFieldErrors({})
    setselectSchoolOption(values)
  }

  const handleStatusChange = (e) => {
    setselectSchoolOption(undefined)
  };


  const handleCheckboxChange = (e, buttonName) => {
    clearErrors();

    if (buttonName === "studentsCollab") {
      setCollab(e.target.checked)
    } else if (buttonName === "counselingAllowed") {
      setAdvisorCounsil(e.target.checked)
    } else if (buttonName === "subCouncelingServices") {
      setProfessionalCounsil(e.target.checked)
    }
    else if (buttonName === "helpwith") {
      setHelpWith(e.target.checked)
    }

  }

  const loadZipcodeOptions = (value) => {
    if (value === "") {
      setZipCodeOption([]);
      return;
    }
    if (value) {
      setLoadZipcodes(true);
      let payload = {
        searchString: value,
        pageNo: 1,
        limit: 80,
      };
      loadZipcode(payload)
        .then((res) => {
          const list = res?.data?.data.map((item) => ({
            label: item.zip,
            value: item.zip
          }));
          setZipCodeOption(list);
          setLoadZipcodes(false);
        })
        .catch((error) => {
          console.error("Error loading zip code options:", error);
          setZipCodeOption([]);
          setLoadZipcodes(false);
        });
    }
  };

  return (
    <>
      {loading && <Loader />}

      {
        props.show &&
        <Modal
          style={styles.modal}
          open={props.show}
          onClose={handleCloseBothPopup}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="goalsModal"
        >
          <div style={styles.paper}>
            <div style={styles.header}>
              <Typography variant="h6" id="modal-title">{props.title}</Typography>
              <Button onClick={() => handleClosee()}><CloseIcon sx={{ fill: 'black' }} /></Button>
            </div>
            <div style={styles.content}>
              <form id='my-formss' onSubmit={handleSubmit(onSubmit, handleValidation)}>

                <h1 className="role_title text-center"><SchoolOutlinedIcon sx={{ fontSize: '3rem' }} /> {props?.data?.id ? `Edit ${props?.data?.fullName} Profile` : 'Add child profile'} </h1>



                <div className="row m-0 col-md-4 col-sm-12 col-xs-12 mx-auto justify-content-center">
                  <div className="col-md-6 col-sm-12 col-xs-12 col-12 ps-0 p-mob-0">
                    <div className="input-floating-label">
                      <input type="text"  {...register("firstName")} onChange={(e) => { onValidationChange("firstName"); }} className={"textbox--primary textbox--rounded input"} name="firstName" placeholder=" " />
                      <label>{FIRST_NAME_LABEL}</label>
                      <div className="error-text">{errors.firstName?.message}</div>
                      {errorList?.firstName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.firstName}</div>}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12  col-12 pe-0 p-mob-0">
                    <div className="input-floating-label">
                      <input type="text"   {...register("lastName")} onChange={(e) => { onValidationChange("lastName"); }} className={"textbox--primary textbox--rounded input"} name="lastName" placeholder=" " />
                      <label>{LAST_NAME_LABEL}</label>
                      <div className="error-text">{errors.lastName?.message}</div>
                      {errorList?.lastName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.lastName}</div>}
                    </div>
                  </div>
                </div>
                <div className="row m-0  col-md-4 mx-auto   justify-content-center">
                  <div className="col-md-6 col-sm-12 col-xs-12 ps-0 p-mob-0">
                    <Controller
                      control={control}
                      name="country"
                      render={({ field }) => (
                        <div className="input-floating-label">
                          <Select placeholder={COUNTRY_NAME_LABEL} {...field} getOptionLabel={option => option.countryName} getOptionValue={option => option.isoCode2Digit} closeMenuOnSelect={true} isClearable={false}
                            isSearchable={false} classNamePrefix="mySelect" options={props?.countryOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                          <div className="error-text" style={{ "position": "absolute" }} >{errors.country?.message}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 pe-0 p-mob-0">
                    <Controller
                      name="zipCode"
                      control={control}
                      defaultValue=""
                      render={({ field, onChange }) => (
                        <Autocomplete {...field}
                          className="goals__form__select input-floating-label" id="zipCode" name="zipCode" type='text'
                          freeSolo clearIcon={false} filterOptions={(options, state) => options}
                          options={zipCodeOption?.map((option) => {
                            return { label: option.label, value: option.value }
                          }) ?? []}
                          getOptionLabel={(option) => {
                            if (typeof option === 'object' && option !== null && 'label' in option) {
                              return option.label.toString();
                            } else {
                              return option.toString();
                            }
                          }}
                          onChange={(event, option) => field.onChange(option ? option.value : '')}
                          onInputChange={async (event, value) => { loadZipcodeOptions(value) }}
                          renderInput={(params) => (
                            <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                              <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
                              <label>{ZIP_CODE_LABEL}</label>
                              {errors?.zipCode && <div className="error-text">{errors.zipCode.message}</div>}

                            </div>
                          )}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row m-0  col-md-4 mx-auto   justify-content-center">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <Controller
                      control={control}
                      name="timeZone"
                      render={({ field }) => (
                        <div className="input-floating-label">
                          <Select placeholder={"Time Zone"} {...field} closeMenuOnSelect={true} isClearable={false}
                            isSearchable={false} classNamePrefix="mySelect" options={timezonesOptions} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                          {/* <div className="error-text" style={{ "position": "absolute" }} >{errors.timeZone?.message}</div> */}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="row m-0  col-md-4 mx-auto   justify-content-center">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <Controller
                      control={control}
                      name="gender"
                      render={({ field }) => (
                        <div className="input-floating-label">
                          <Select placeholder={GENDER_LABEL} {...field} closeMenuOnSelect={true} isClearable={false}
                            isSearchable={false} classNamePrefix="mySelect" options={GenderOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                          <div className="error-text" style={{ "position": "absolute" }}>{errors.gender?.message}</div>
                        </div>
                      )} />
                  </div>
                </div>
                <div className="row m-0  col-md-4 mx-auto  justify-content-center">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <Controller
                      control={control}
                      name="mystatus"
                      render={({ field }) => (
                        <div className="input-floating-label">
                          <Select placeholder="Child's Status" isDisabled={props?.data?.id} {...register("mystatus", {
                            onChange: (e) => handleStatusChange(e)
                          })}  {...field} getOptionLabel={option => option.label} getOptionValue={option => option.label} closeMenuOnSelect={true} isClearable={false}
                            isSearchable={false} classNamePrefix="mySelect" options={statusList} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                          <div className="error-text" style={{ "position": "absolute" }} >{errors.mystatus?.message}</div>
                        </div>
                      )} />
                  </div>
                </div>
                <div className="row m-0 col-md-4  mx-auto  justify-content-center">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <Controller
                      control={control}
                      name="year"
                      render={({ field }) => (
                        <div className="input-floating-label">
                          <Select placeholder={GRADUATION_YEAR_LABEL} {...field} closeMenuOnSelect={true} isClearable={false}
                            isSearchable={false} classNamePrefix="mySelect" options={Graduationyear} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                          <div className="error-text" style={{ "position": "absolute" }} >{errors.year?.message}</div>
                        </div>
                      )} />
                  </div>
                </div>
                <div className="row m-0 col-md-4  mx-auto  justify-content-center">
                  {watch("mystatus")?.role === "university" ? (
                    <div className="col-md-12 col-sm-12 col-12 p-0 multinew"  >
                      <Autocomplete
                        className="goals__form__select" id="tags-filled" name="highschool"
                        type='text' freeSolo clearOnBlur={false} clearIcon={false}
                        options={highSchoolData?.length > 0 ? highSchoolData.map((option) => option.label) : []}
                        filterOptions={(options, state) => options}
                        value={selectSchoolOption !== undefined ? selectSchoolOption?.label || selectSchoolOption : ""}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(item) => (item ? `${item} ` : "")}
                        onChange={onTagsChange}
                        onInputChange={async (event, value) => { loadUniversityOptions(value) }}
                        renderInput={(params) => (
                          <div className="input-floating-label" ref={params.InputProps.ref} >
                            <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="highSchool" placeholder=" " />
                            <label>{"University Name"}</label>
                            <div className="error-text">{fieldErrors?.highSchoolName}</div>
                          </div>
                        )}
                      />
                    </div>
                  ) : (
                    <div className="col-md-12  col-sm-12 col-12 p-0 multinew"  >
                      <Autocomplete
                        className="goals__form__select" id="tags-filled" name="highschool"
                        type='text' freeSolo clearOnBlur={false} clearIcon={false}
                        options={highSchoolData?.length > 0 ? highSchoolData.map((option) => option.label) : []}
                        filterOptions={(options, state) => options}
                        value={selectSchoolOption !== undefined ? selectSchoolOption?.label || selectSchoolOption : ""}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(item) => (item ? `${item} ` : "")}
                        onChange={onTagsChange}
                        onInputChange={async (event, value) => { loadOptions(value) }}
                        renderInput={(params) => (
                          <div className="input-floating-label" ref={params.InputProps.ref} >
                            <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="highSchool" placeholder=" " />
                            <label>{"High School Name"}</label>
                            <div className="error-text">{fieldErrors?.highSchoolName}</div>
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>

                <div className="row m-0  col-md-4 mx-auto   justify-content-center">
                  <div className="col-md-6 col-sm-12 col-xs-12 ps-0 p-mob-0">
                    <div className="input-floating-label">
                      <input {...register("currentGpa")} onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} type="text" className={"textbox--primary textbox--rounded input"} name="currentGpa" placeholder={GPA_LABEL} />
                      <label>{"Weighted GPA"}</label>
                      <div className="error-text">{errors.currentGpa?.message}</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 pe-0 p-mob-0">
                    <div className="input-floating-label">
                      <input {...register("unWeighted")} onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} type="text" className={"textbox--primary textbox--rounded input"} name="unWeighted" placeholder={GPA_LABEL} />
                      <label>{"Unweighted GPA"}</label>
                      <div className="error-text">{errors.unWeighted?.message}</div>
                    </div>

                  </div>
                </div>

                <div className="row  col-md-4  mx-auto  m-0 justify-content-center">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="input-floating-label">
                      <input {...register("email")} onChange={(e) => { onValidationChange("email"); }} type="email" className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                      <label>{EMAIL_LABEL}</label>
                      <div className="error-text">{errors.email?.message}</div>
                      {errorList?.email && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.email}</div>}
                    </div>
                  </div>
                </div>
                <div className="row m-0  col-md-4 mx-auto  justify-content-center">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <p className="profile__form__sub-title">I need help with</p>
                    <div className="grid-container" style={{ display: 'grid' }}>
                      <FormControlLabel control={<Checkbox  {...register("checkboxes.studentsCollab")} onClick={(e) => handleCheckboxChange(e, 'studentsCollab')} checked={collab === true ? true : false} />} label="Collaborating with students with similar goals" />
                      <FormControlLabel control={<Checkbox {...register("checkboxes.counselingAllowed")} onClick={(e) => handleCheckboxChange(e, 'counselingAllowed')} checked={advisorCounsil === true ? true : false} />} label="Selecting courses/activities to meet my goal" />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={(e) => handleCheckboxChange(e, 'helpwith')}
                            {...register("checkboxes.helpwith")}
                            checked={helpwith === true ? true : false}
                          />
                        }
                        label={
                          <React.Fragment>
                            Help with course/activity milestones through school year
                            <Tooltip title="Assignments, homework, test prep etc" placement="right">
                              <IconButton>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                  <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel control={<Checkbox {...register("checkboxes.subCouncelingServices")} onClick={(e) => handleCheckboxChange(e, 'subCouncelingServices')} checked={professionalCounsil === true ? true : false} />} label="Professional counseling services" />

                    </div>
                    <div className="error-text">{errors.checkboxes?.message}</div>
                  </div>
                </div>
                <div style={styles.footer}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4} className='text-center d-flex'>
                      {/* <KeyboardBackspaceIcon className="backArrow" onClick={() => handleClosee()} />
                      <p onClick={() => handleClosee()} style={{ cursor: 'pointer' }}>Go back</p> */}
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} className='text-center'>

                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Button type="submit" style={{ float: 'right' }} disabled={buttonClicked} className="profilesavebtn"> Save</Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      }
    </>
  );
}
const mapStateToProps = ({ userProfileData, commonData }) => {
  const { userProfile, isProfileSuccess, childprofile, parentProfile, isChildProfileSucess, isEditChildError, isCreateChildError, editChildProfileSucess } = userProfileData;
  const { status, gender, country, graduationyear, schools } = commonData;
  return { userProfile, isProfileSuccess, status, gender, schools, country, isCreateChildError, isEditChildError, childprofile, isChildProfileSucess, editChildProfileSucess, parentProfile, graduationyear };
};
export default connect(mapStateToProps, { setChildProfile, getGender, editChildProfile, clearErrorList, editIsChildProfileError, editIsChildProfileSuccess, setIsChildProfileSuccess, setIsChildProfileError })(Index);
