import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { GetActivityList, GetActivityListStatus, activitiesApprove, activityApproveStatus, activityApproveErrorStatus, activitiesReject, activityRejectStatus, activityDropConfirmation, activityDropConfirmationStatus, activityDropErrorStatus } from '../../../../redux/actions';
import ActivityCard from "../../../../componentsNew/Common/Cards/MyWork/ActivityCard";
import ManageAdvisorPopup from '../../../../componentsNew/Common/MyworkModal/ManageAdvisor';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Loader from "../../../../components/Loader";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Rejectcourse from "../Popups/acceptcourse";
import { useParams, useNavigate } from "react-router-dom";
import { useImageUrl } from "../../../../utils/UseImageURL";

const Index = (props) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [showadvisor, setshowadvisor] = useState(false);
  const [plandata, setplandata] = useState();
  const [showreject, setshowreject] = useState(false);
  const [activityReject, setshowActivityReject] = useState(false);
  const [acceptCourse, setacceptCourse] = useState(false)
  const Networks = /\/student\/[^\/]+\/mywork\/?$/.test(window?.location?.pathname);
  const { id } = useParams();

  useEffect(() => {
    setloading(true)
    let value = {
      searchString: "",
      pageNo: 1,
      limit: 40,
      planIds: [],
      studentId: id ? id : null
    }
    props.GetActivityList(value)
  }, [])

  useEffect(() => {
    if (props?.getactivityliststatus) {
      props?.GetActivityListStatus(false)
      setloading(false)
    }
    if (props?.isActivityAproved) {
      props?.activityApproveStatus(false)
      let value = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props.GetActivityList(value)
    }
    if (props?.isActivityAprovedError) {
      props?.activityApproveErrorStatus(false)
      let value = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props.GetActivityList(value)
    }
    if (props?.isactivityRejected) {
      props?.activityRejectStatus(false)
      let value = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props.GetActivityList(value)
    }
    if (props?.activityDropStatus) {
      props?.activityDropConfirmationStatus(false)
      let value = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props.GetActivityList(value)
    }
    if (props?.activityDropError) {
      props?.activityDropErrorStatus(false)
      let value = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props.GetActivityList(value)
    }
  }, [props?.getactivityliststatus, props?.isActivityAproved, props?.isActivityAprovedError, props?.isactivityRejected, props?.activityDropStatus, props?.activityDropError])

  const OpenMangeAdvisor = (e, data) => {
    e.preventDefault();
    setplandata(data)
    setshowadvisor(true)
  }
  const CloseMangeAdvisor = () => {
    setshowadvisor(false)
    setplandata({})
  }
  const getSuggestionDetail = (isApproved, isDropSuggested, type) => {
    if (isApproved && isDropSuggested) {
      return (
        <p className='myWork-courseCard__suggestionSec__dangerText'>
          Advisor suggested to drop this {type}. can now review and accept or reject your proposal
          <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon' />
        </p>
      );
    } else if (!isApproved) {
      return (
        <p className='myWork-courseCard__suggestionSec__successText'>
          You can now review and accept or reject Advisor proposal
          <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon' />
        </p>
      );
    }
  }
  const OpenRejectActivitypopup = (e, data) => {
    console.log("OpenRejectActivitypopup data", data);
    e.preventDefault();
    setplandata(data)
    setshowActivityReject(true)
  }
  const Cloaseplanrejectpopup = () => {
    setshowreject(false)
    setshowActivityReject(false)
  }
  const acceptActivity = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      phaseId: data?.activityInfo?.phaseId,
      planId: data?.activityInfo?.planId,
    }
    props?.activitiesApprove(data?.activityInfo?.id, payload)
  }
  const rejectActivity = (data) => {
    setloading(true)
    let payload = {
      phaseId: data?.activityInfo?.phaseId,
      planId: data?.activityInfo?.planId,
    }
    props?.activitiesReject(data?.activityInfo?.id, payload)
  }
  const acceptDropActivity = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "activityId": data?.activityInfo?.id,
      "phaseId": data?.activityInfo?.phaseId,
      "planId": data?.activityInfo?.planId,
      "status": true
    }
    props?.activityDropConfirmation(payload)
  }

  const rejectDropActivity = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "activityId": data?.activityInfo?.id,
      "phaseId": data?.activityInfo?.phaseId,
      "planId": data?.activityInfo?.planId,
      "status": false
    }
    props?.activityDropConfirmation(payload)
  }
  const handleNavigate = (e) => {
    e.preventDefault();
    navigate('/student/mynetwork/exploreAdvisor')
  }
  const handleNavigateMilestone = (e, data) => {
    e.preventDefault();
    if(Networks){
      navigate(`plan/${data?.activityInfo?.planId}/activityMilestone/${data?.activityInfo?.id}`, { state: { activityData: data?.activityInfo,studentView: true } })
    }
    else{
      navigate(`plan/${data?.activityInfo?.planId}/activityMilestone/${data?.activityInfo?.id}`, { state: { activityData: data?.activityInfo } })

    }
  }
  const AvatarWithUrl = ({ avatarUrl, onClick }) => {
    const imageUrl = useImageUrl(avatarUrl);
    return <Avatar alt={imageUrl} src={imageUrl} onClick={onClick} />;
  };
  return (
    <>
      {loading && <Loader />}
      {showadvisor && <ManageAdvisorPopup show={showadvisor} onHide={CloseMangeAdvisor} data={plandata} text={"Activity"} />}
      {activityReject && <Rejectcourse show={activityReject} onHide={Cloaseplanrejectpopup} data={plandata} title={"Reject Activity"} RejectPlans={(data) => rejectActivity(data)} />}
      <div className='myWork-studentActivitiesSec'>
        {props?.getactivitylist?.map(data => {
          const subscribedAdvisors = data?.subscribedAdvisors || [];
          console.log("subscribedAdvisors",subscribedAdvisors);
          
          const avatars = subscribedAdvisors?.map(subscribedAdvisor => {
            const connectedAdvisor = props?.connectedAdvisors?.find(advisor => advisor?.userInfo.id === subscribedAdvisor);
            return connectedAdvisor?.userInfo?.avatarPath;
          });
          console.log(data?.activityInfo?.isApproved,"data?.activityInfo?.isDropsuggested",data?.activityInfo?.isDropsuggested)
          return (
            
            <ActivityCard key={data?.activityInfo?.id}
              activityName={data?.activityInfo?.activityName}
              activityType={data?.activityInfo?.activityType}
              description={data?.activityInfo?.description}
              semester={data?.activityInfo?.planPhase?.title}
              isApproved={data?.activityInfo?.isApproved}
              isDropsuggested={data?.activityInfo?.isDropsuggested}
              suggestionData={getSuggestionDetail(data?.activityInfo?.isApproved, data?.activityInfo?.isDropsuggested, 'Activity')}
              planName={(data?.activityInfo?.planId === data?.planInfo?.id) && data?.planInfo?.planName}
              isArchived={data?.activityInfo?.isArchived ?? false}
            >
              {!Networks &&
                <>
                  <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec">
                    {data?.activityInfo?.isApproved && 
                    <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__flatBtn" onClick={(e) => handleNavigateMilestone(e, data)}>
                      View Milestones
                    </div>
                    }
                    {(data?.activityInfo?.isApproved && data?.activityInfo?.isDropsuggested) &&
                      <div className="myWork-planCard__btnSection">
                        <div className="myWork-planCard__btnSection__Cnt">
                          <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => rejectDropActivity(e, data)}>
                            Reject
                          </div>
                          <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptDropActivity(e, data)}>
                            Accept
                          </div>
                        </div>
                      </div>
                    }
                    {!data?.activityInfo?.isApproved &&
                      <div className="myWork-planCard__btnSection">
                        <div className="myWork-planCard__btnSection__Cnt">
                          <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => OpenRejectActivitypopup(e, data)}>
                            Reject
                          </div>
                          <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptActivity(e, data)}>
                            Accept
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {(data?.activityInfo?.isApproved && !data?.activityInfo?.isDropsuggested) &&
                    <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec">
                      {!data?.activityInfo?.isArchived &&
                        <div className="mt-3">
                          <div style={avatars?.length > 0 ? { "display": "flex", "gap": "10px", "width": "100%", "alignItems": "center" } : { "width": "100%" }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                              <AvatarGroup max={2}>
                                {avatars.map((avatar, index) => (
                                  <AvatarWithUrl key={index} avatarUrl={avatar} onClick={avatars?.length === 1 ? (e) => OpenMangeAdvisor(e, data) : undefined} />
                                ))}
                              </AvatarGroup>
                              {avatars?.length === 1 &&
                                <ChatBubbleOutlineOutlinedIcon style={{ marginLeft: 'auto', marginTop: '0px', fontSize: "12px", "cursor": "pointer" }} />
                              }
                            </div>
                            <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__outlineBtn" onClick={(e) => OpenMangeAdvisor(e, data)} style={{ width: avatars.length > 0 ? "100%" : "100%", "fontSize": avatars.length > 0 ? "16px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", }}>
                              Manage Advisors
                            </div>
                          </div>
                        </div>
                      }
                      <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__flat-btn" onClick={(e) => handleNavigate(e)}>
                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11" cy="11" r="6" stroke="#222222" />
                            <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                            <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                          </svg>
                        </span>
                        Explore Advisors
                      </div>
                    </div>
                  }
                </>
              }
              {Networks &&
                    <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec">
                      <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__flatBtn" onClick={(e) => handleNavigateMilestone(e, data)} >
                        View Milestones
                      </div>
                    </div>
                  }
            </ActivityCard>
          )
        })}
        {props?.getactivitylist?.length === 0 &&
          <div className="semesterCourseSec__noDataSec" style={{ "width": "100%" }}>
            <div className="semesterCourseSec__noDataSec__noDataCard">
              <p className="semesterCourseSec__noDataSec__noDataCard__textContent">
                No Activities Available
              </p>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const mapStateToProps = ({ studentData, commonData }) => {
  const { connectedAdvisors, connectedAdvisorsStatus } = commonData;
  const { getactivitylist, getstudentplan, getactivityliststatus, isActivityAproved, isActivityAprovedError, isactivityRejected, activityDropStatus, activityDropError } = studentData;
  return { getactivitylist, getstudentplan, getactivityliststatus, isActivityAproved, isActivityAprovedError, connectedAdvisors, connectedAdvisorsStatus, isactivityRejected, activityDropStatus, activityDropError }
};

export default connect(mapStateToProps, { GetActivityList, GetActivityListStatus, activitiesApprove, activityApproveStatus, activityApproveErrorStatus, activitiesReject, activityRejectStatus, activityDropConfirmation, activityDropConfirmationStatus, activityDropErrorStatus })(Index);