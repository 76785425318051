import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "../../../assets/css/common.less";

const PillsDisplay = (props) => {
  const pills = props?.text || []; // Ensure pills is an array, even if undefined
  const navigate = useNavigate(); // Get the navigate function from useNavigate

  // Show only the first 5 pills
  const pillsToShow = pills.slice(0, 6);
  const remainingPills = pills.slice(6); // Pills after the first 5
  const remainingPillsCount = pills.length > 6 ? pills.length - 6 : 0;

  // Popover state
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleTagClick = (data) => {
    if (props?.user === "student") {

      navigate(`/student/mywork/plan/${data?.planId}/courseMilestone/${data?.courseId}`);
    } else {
      if (data?.courseId) {
        navigate(`/parent/mywork/plan/${data?.planId}/milestones/${data?.courseId}`, { state: { courseData: data } });
      } else if (data?.activityId) {
        navigate(`/parent/mywork/plan/${data?.planId}/activity/milestone/`, { state: { courseData: data } });

        // navigate(`/parent/mywork/plan/${data?.planId}/milestones/${data?.activityId}`, { state: { courseData: data } });
      }
    }

  };

  const renderPills = () => {
    return pillsToShow.map((pill, index) => {
      const isSelected = props?.isSelected === pill.activityName; // Compare using activityName or other identifier
      const isClickable = props?.isClickable !== undefined ? props.isClickable : true;

      const tagStyles = {
        height: "40px",
        padding: '3px 10px',
        margin: '0 10px 10px 0',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E3E3E3',
        cursor: 'pointer'
      };

      const tagTextStyles = {
        marginBottom: '0',
        fontSize: '13px',
        lineHeight: '22px',
        fontFamily: props?.bold ? '@font--poppins-600' : '@font--poppins-300',
        color: '#000000'
      };

      return (
        <div
          key={index}
          className={`tag__tags-chip ${isSelected ? 'tag__tags-chip--clicked' : ''}`}
          style={tagStyles}
          onClick={() => isClickable && handleTagClick(pill)} // Call handleTagClick with the pill data
        >
          <p className={`Chips tag__tags-chip__tag-text${props?.bold ? "-bold" : "-light"}`} style={tagTextStyles}>
            {pill.activityName || pill.courseName}
          </p>
        </div>
      );
    });
  };

  return (
    <>
      <div className="pills-container" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {renderPills()}

        {/* Remaining Pills as a Pill with Popover */}
        {remainingPillsCount > 0 && (
          <>
            <div
              className="tag__tags-chip"
              style={{
                height: "40px",
                padding: '3px 10px',
                margin: '0 10px 10px 0',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#E3E3E3',
                cursor: 'pointer'
              }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <p className="Chips tag__tags-chip__tag-text-bold" style={{ marginBottom: '0', fontSize: '13px', lineHeight: '22px' }}>
                +{remainingPillsCount}
              </p>
            </div>

            {/* Popover Component */}
            <Popover
              id="mouse-over-popover"
              sx={{ pointerEvents: 'none' }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 2 }}>
                {remainingPills.map((pill, index) => (
                  <div
                    key={index}
                    onClick={() => handleTagClick(pill)} // Navigate on click
                    style={{
                      cursor: 'pointer',
                      marginBottom: '5px',

                    }}
                  >
                    {pill.activityName || pill.courseName}
                  </div>
                ))}
              </Typography>
            </Popover>
          </>
        )}
      </div>
    </>
  );
};

export default PillsDisplay;
