import React, { useState } from "react";
// import "../../../../../assets/css/editPlan.less"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MileStoneTab from "./MileStoneTab";
import AddMilestone from "../../../../componentsNew/Common/addMilestone"
import { Box, Grid, Tab, Tabs, } from "@mui/material";
import { DeleteCourse, GetMilestoneByActivity, createMilestonforActivityStatus, GetMileStoneByActivityStatus, DeleteMileStoneResourceStatus, DeleteMileStoneStatus, DeleteMileStoneNotesStatus, CreateNotesStatus, updateReadUpdateStatus } from "../../../../redux/actions";
import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { connect } from "react-redux";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomAlert from "../../../../components/CustomAlert";
import Tooltip from '@mui/material/Tooltip';
import "../MilesSection/milesection.less"



const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            className="Advisor-MyNetwork__tabs-sec"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="card-sec">
                    {children}
                </Box>
            )}
        </div>
    );
}

const Index = (props) => {
    const navigate = useNavigate();
    const {state} = useLocation();

    const [showChangeHistory, setshowChangeHistory] = useState(false)
    const [key, SetKey] = useState("Milestone1")
    const [milestoneshow, setmilestoneshow] = useState(false)
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);

    const params = useParams();

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };
    useComponentDidUpdate(() => {
        const milesTab = props?.activityMilestone.findIndex(milestone => milestone.id === state?.milestoneData?.id);
        let tabIndex = Math.abs(milesTab);
        if (!isNaN(tabIndex) && tabIndex >= 0) {
            setTab(tabIndex);
        } else {
            setTab(0);

        }
    }, [state]);

    useComponentDidMount(() => {
        let payload = {
            "planId": params.id,
            "activityId": state?.activityData?.id || state?.courseData?.activityId,
            "studentId": localStorage?.getItem("parentViaChild")
        }
        props?.GetMilestoneByActivity(payload)
        setLoading(true)
    }, [])

    useComponentDidUpdate(() => {
        let isFound = false;
        props?.activityMilestone?.map((milestoneData, index) => {
            if (!isFound && milestoneData?.status === "In Progress") {
                isFound = true
                setTab(index)
            } else {
                setTab(0)

            }
        })
    }, [props?.activityMilestone])

    const openChangeHistory = (value) => {
        setshowChangeHistory(value)
    }
    const goback = (e) => {
        e.preventDefault();
        navigate(-1)
    }
    const setKey = (k) => {
        SetKey(k)
    }
    const Openmilestone = () => setmilestoneshow(true);
    const Closemilestone = () => setmilestoneshow(false);
    const handleClick = () => {
        Closemilestone()
    };

    useComponentDidUpdate(() => {
        let payload = {
            "planId": params.id,
            "activityId": state?.activityData?.id ,
            "studentId": localStorage?.getItem("parentViaChild")
        }
        if (props.createActivityMile) {
            props.createMilestonforActivityStatus(false)
            props?.GetMilestoneByActivity(payload)
            Closemilestone()
        }
        if (props?.isDeleteResource) {
            props?.DeleteMileStoneResourceStatus(false)
            props?.GetMilestoneByActivity(payload)
        }
        if (props?.isMilestoneDeleted) {
            props?.GetMilestoneByActivity(payload)
            props?.DeleteMileStoneStatus(false)
        }
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false)
            props?.GetMilestoneByActivity(payload)
        }
        if(props?.isactivityMilestoneStatus){
            props?.GetMileStoneByActivityStatus(false);
        }
        if(props?.isUpdateNotes){
            props?.CreateNotesStatus(false)
            props?.GetMilestoneByActivity(payload)
        }
        if(props?.isReplyNotes){
            props?.GetMilestoneByActivity(payload)
        }
        if(props?.viewedUpdate){
            props?.updateReadUpdateStatus(false)
            props?.GetMilestoneByActivity(payload)
        }
    }, [props.createActivityMile, props?.isactivityMilestoneStatus, props?.isDeleteResource, props?.isMilestoneDeleted,props?.deleteNotes,props?.isUpdateNotes,props?.isReplyNotes,props?.viewedUpdate])

    return (
        <>

            <div>
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
                <div className="planviewcnt activityMiles">
                    <div className="planviewcnttopsec">
                        {/* Plan mobile view */}
                        <div className="planviewmobile milesTopMobile">
                            <div className="plantopmobile">
                                <p className="mobileviewtitle">My Work</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                                <div className="planMaintitle">
                                    <p>
                                        <span className="planbackbtn" onClick={(e) => goback(e)} >
                                            <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 8.00016C13.3333 7.63197 13.0349 7.3335 12.6667 7.3335H3.33334C2.96515 7.3335 2.66668 7.63197 2.66668 8.00016C2.66668 8.36835 2.96515 8.66683 3.33334 8.66683H12.6667C13.0349 8.66683 13.3333 8.36835 13.3333 8.00016Z" fill="#1B1C1E" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.47139 2.86177C8.21104 2.60142 7.78893 2.60142 7.52859 2.86177L2.86192 7.52843C2.60157 7.78878 2.60157 8.21089 2.86192 8.47124L7.52859 13.1379C7.78893 13.3983 8.21104 13.3983 8.47139 13.1379C8.73174 12.8776 8.73174 12.4554 8.47139 12.1951L4.27613 7.99984L8.47139 3.80458C8.73174 3.54423 8.73174 3.12212 8.47139 2.86177Z" fill="#1B1C1E" />
                                            </svg>
                                        </span> {" "}
                                        Activity:{" "}{state?.activityData?.activityName}
                                    </p>
                                </div>
                            </div>

                        </div>
                        {props?.activityMilestone && props?.activityMilestone?.length > 0 ?
                            (
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                                        <Box
                                            className="tab_card"
                                            sx={{
                                                bgcolor: "#fff",
                                                borderRadius: "10px",
                                                border: "1px solid #CDCDCD",
                                                marginInline: "auto",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Tabs
                                                value={tab}
                                                onChange={changeTab}
                                                variant="scrollable"
                                                scrollButtons
                                                allowScrollButtonsMobile
                                                aria-label="scrollable force tabs example"
                                            >
                                                {props?.activityMilestone && props?.activityMilestone?.length > 0 && props?.activityMilestone.map((milestone) => (
                                                    <Tab
                                                        key={milestone?.id}
                                                        label={milestone?.milestoneTitle}
                                                        disableRipple
                                                    />
                                                ))}
                                            </Tabs>
                                            {
                                                props?.activityMilestone && props?.activityMilestone?.length < 25 && (
                                                    <Tooltip title="Create Milestone" placement="bottom">
                                                        <button className="tab_card__addMileStonebtn" onClick={Openmilestone}>
                                                            <AddOutlinedIcon sx={{ fontSize: "18px" }} />
                                                        </button>
                                                    </Tooltip>
                                                )
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                                        {props?.activityMilestone?.map((milestoneData, index) => {
                                            return (
                                                <TabPanel value={tab} index={index}>
                                                    <MileStoneTab data={milestoneData} />
                                                </TabPanel>
                                            )
                                        })}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                                        <div className="planviewcnttopsec__emptyState">
                                            <div className="planviewcnttopsec__emptyState__content">
                                                <div>
                                                    <p className="planviewcnttopsec__emptyState__content__empty-text">
                                                        You do not have created any Milestones for this activity
                                                    </p>
                                                    <p className="planviewcnttopsec__emptyState__content__empty-cta" onClick={Openmilestone} >
                                                        Create Milestone
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            )
                        }




                    </div>
                </div>
            </div>
            {
                milestoneshow &&
                <AddMilestone show={milestoneshow} onHide={() => Closemilestone()} handleClose={handleClick} />
            }

        </>
    );
}
const mapStateToProps = ({ studentData }) => {
    const { activityMilestone, createActivityMile, isactivityMilestoneStatus, isUpdateNotes,isReplyNotes,viewedUpdate, deleteNotes } = studentData;
    return { activityMilestone, createActivityMile, isactivityMilestoneStatus, isUpdateNotes,isReplyNotes,viewedUpdate, deleteNotes };
};

export default connect(mapStateToProps, { GetMileStoneByActivityStatus, CreateNotesStatus, DeleteMileStoneResourceStatus, DeleteMileStoneStatus, DeleteMileStoneNotesStatus, updateReadUpdateStatus,  DeleteCourse, GetMilestoneByActivity, createMilestonforActivityStatus })(Index);                                                                                                                                                                                                                                                                                                                                                                                               
