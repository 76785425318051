import React, { useState, useEffect } from "react";
import "./parentMyNetwork.less";
import { connect } from "react-redux";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus } from '../../../redux/actions'
import { Box, Grid, Tab, Tabs, Button } from "@mui/material";
import Select, { components } from "react-select";
import AdvisorProfile from "../../../componentsNew/Common/advisorProfile";
import ConnectedStudents from "./ConnectedStudents";
import ReceivedRequest from "./ReceivedRequest";
import SentRequest from "./SentRequest";
import Advisor from "./ConnectedAdvisor"
import CustomAlert from "../../../components/CustomAlert";
import { useLocation, useNavigate } from "react-router-dom";


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="Advisor-MyNetwork__tabs-sec"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="card-sec">
                    {children}
                </Box>
            )}
        </div>
    );
}

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [tab, setTab] = useState(0);
    const [selectedOption, setSelectedOption] = useState({ value: 0, label: "Advisor" });
    const [profilePopup, setProfiePopup] = useState(false);
    const [profileData, setProfileData] = useState({});
    const location = useLocation();
    const [activeTab, setActiveTab] = React.useState(0);
    let navigate = useNavigate();

    const profileHide = () => setProfiePopup(false);

    const menulist = [
        { value: 0, label: "Advisor" },
        { value: 1, label: "Student" },
        { value: 2, label: "Sent Requests" },
        { value: 3, label: "Received Requests" },

    ]

    useEffect(() => {
        props?.getChildProfile()
    }, [])

    useEffect(() => {
        if (location.state?.activeTab) {
            setTab(location.state.activeTab);
        }
    }, [location.state]);

    console.log(location.state, "location.state")


    useEffect(() => {
        let childId = localStorage.getItem("parentViaChild")
        props?.getUserCount(childId)
    }, [props?.childprofile])


    const changeTab = (e, newValue) => {
        setTab(newValue);
        props.clearMessage();
        sessionStorage.setItem('netvalue', JSON.stringify(newValue));
    };
    const handleSelectChange = (e, newValue) => {
        sessionStorage.setItem('netvalue', JSON.stringify(e.value));
        setTab(e.value);
        setSelectedOption(e);
        // setSelectedOption(newValue);
        // sessionStorage.setItem('netvalue', JSON.stringify(newValue));

    }
    const a11yProps = (index) => {
        return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
    }


    return (
        <>
            {<div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
            {profilePopup && <AdvisorProfile show={profilePopup} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} />}
            <div className="Parent-MyNetwork__mainCnt">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                        <div className="Advisor_Network_Sec">
                            <p className="Network_Title">My Network</p>
                        </div>
                        <div className="Advisor_Network_Sec_Mobile">
                            <p className="Network_Title">My Network</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7.7} xl={7.7} >
                        <div className="parent_Profile_Sec_Mobile" style={{ marginTop: '110px' }}>
                            <div style={{ "display": "flex", "flexWrap": "wrap" }}>
                                <div className="input-floating-label" style={{ marginBottom: '6px' }}>
                                    <Select options={menulist} onChange={handleSelectChange}
                                        // defaultValue={Menulists[0]}
                                        value={selectedOption}
                                        getOptionLabel={e => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {e.icon}
                                                <span style={{ marginLeft: 10 }}>{e.label}</span>
                                            </div>
                                        )}
                                        placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Box
                            className="tab_card Advisor_Network_Sec"

                        >
                            <Tabs
                                value={tab}
                                onChange={changeTab}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                <Tab {...a11yProps(0)} label={`${(props?.totalUserCount?.advisorCount ? props?.totalUserCount?.advisorCount : 0)} Advisors`} disableRipple />
                                <Tab {...a11yProps(1)} label={`${props?.totalUserCount?.studentCount ?? ""} Students`} disableRipple />
                                <Tab {...a11yProps(2)} label={`${props?.totalUserCount?.sentRequestCount ?? ""} Sent Requests`} disableRipple />
                                <Tab {...a11yProps(3)} label={`${props?.totalUserCount?.pendingRequestCount ?? ""} Received Requests`} disableRipple />

                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4.3} xl={4.3} className="mt-0 pl-0 exploreBtns" style={{ display: 'flex', justifyContent: 'end', }}>
                        <div className="Advisor-MyNetwork__searchCnt d-flex" >
                            {tab === 1 && <Button variant="contained" className="btn cta--rounded cta-primary " sx={{ marginRight: '1rem', textTransform: 'capitalize', borderRadius: '10px' }} onClick={() => navigate("/parent/mynetwork/exploreStudents")}> Explore Student</Button>}
                            {tab === 0 && <Button variant="contained" className="btn cta--rounded cta-primary " sx={{ marginRight: '1rem', textTransform: 'capitalize', borderRadius: '10px' }} onClick={() => navigate("/parent/mynetwork/exploreAdvisor")} > Explore Advisor</Button>}

                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box>
                            <TabPanel value={tab} index={0}>
                                <Advisor />
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <ConnectedStudents />
                            </TabPanel>
                            <TabPanel value={tab} index={2}>
                                <SentRequest />
                            </TabPanel>
                            <TabPanel value={tab} index={3}>
                                <ReceivedRequest />
                            </TabPanel>

                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { childprofile } = userProfileData;
    const { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount } = commonData;
    return { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount, childprofile };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, getChildProfile, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus })(Index);
