import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Typography, CardContent, Card } from '@mui/material';
import { connect } from "react-redux";
import "./count.less";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, getAdvisorCountUpdateStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus, viewDashboardCount } from '../../../redux/actions'


const Index = (props) => {
    const navigate = useNavigate();
    let buttonState;
    let navigatePath;

    let advisorName = props?.connectedAdvisors?.filter((data) => data?.userInfo?.id === props?.data?.id)
    console.log(props?.data, "props?.data")
    if (props?.userProfile?.currentRole === "parent") {
        const { data } = props;
        const advisorFirstName = advisorName && advisorName[0]?.userInfo?.firstName || '';
        const milestones = data?.milestone || [];
    
        // Set common paths for advisor and student connection requests
        if (data?.type === "advisor" || data?.type === "student") {
            buttonState = `new ${data?.type} connection requests`;
            navigatePath = "/parent/mynetwork";
        }
        // Handle milestone updates
        else if (data?.type === "milestone updates") {
            const uniqueCourseIds = [...new Set(milestones.map(m => m.courseId))];
            const uniquePlanIds = [...new Set(milestones.map(m => m.planId))];
            const isCourses = milestones.every(m => m.activityId === null);
            const isActivities = milestones.every(m => m.courseId === null);
    
            if (isCourses) {
                navigatePath = milestones.length === 1
                    ? `/parent/mywork/plan/${milestones[0]?.planId}/milestones/${milestones[0]?.courseId}`
                    : uniqueCourseIds.length === 1 && uniquePlanIds.length === 1
                    ? `/parent/mywork/plan/${milestones[0]?.planId}`
                    : `/parent/mywork`;
            } else if (isActivities) {
                const uniqueActivityIds = [...new Set(milestones.map(m => m.activityId))];
                navigatePath = milestones.length === 1
                    ? `/parent/mywork/plan/${milestones[0]?.planId}/activities/${milestones[0]?.activityId}`
                    : uniqueActivityIds.length === 1 && uniquePlanIds.length === 1
                    ? `/parent/mywork/plan/${milestones[0]?.planId}`
                    : `/parent/mywork`;
            } else {
                navigatePath = `/parent/mywork`;
            }
    
            buttonState = `new updates from ${advisorFirstName}`;
        }
        // Handle course and activity updates
        else if (data?.type === "course updates" || data?.type === "activity updates") {
            const uniquePlanIds = [...new Set(data?.courses?.map(m => m.planId))];
            if (uniquePlanIds?.length === 1){
                navigatePath = `/parent/mywork/plan/${milestones[0]?.planId}`;
            } else {
                navigatePath = `/parent/mywork`;
            }
            buttonState = `new ${data?.type.replace(" updates", "")} updates from ${advisorFirstName}`;
        }
    }
     else {
        if (props?.data?.type === "advisor") {
            buttonState = "new advisor connection requests"
            navigatePath = "/student/mynetwork"
        }
        if (props?.data?.type === "student") {
            buttonState = "new student connection requests"
            navigatePath = "/student/mynetwork"
        }
        else if (props?.data?.type === "milestone updates") {
            const milestones = props?.data?.milestone;

            // Get the unique courseId and planId values from the milestone array
            const uniqueCourseIds = [...new Set(milestones.map(milestone => milestone.courseId))];
            const uniquePlanIds = [...new Set(milestones.map(milestone => milestone.planId))];
            if (milestones.length === 1) {
                navigatePath = `/student/mywork/plan/${props?.data?.milestone[0]?.planId}/courseMilestone/${props?.data?.milestone[0]?.courseId}`;

            }
            // Condition 2: If multiple milestones exist with the same courseId and planId, include courseId and planId
            else if (uniqueCourseIds.length === 1 && uniquePlanIds.length === 1) {
                navigatePath = `/student/mywork/plan/${props?.data?.milestone[0]?.planId}`;

            }
            // Condition 3: If multiple milestones have different courseIds but the same planId, only include planId
            else {
                navigatePath = `/student/mywork`;

            }
            console.log(advisorName[0]?.userInfo?.firstName, "advisorName[0]?.userInfo?.firstName")
            buttonState = `new updates from ${advisorName && advisorName[0]?.userInfo?.firstName || ''}`;
        }
        else if (props?.data?.type === "course updates") {
            buttonState = `new course updates from ${advisorName && advisorName[0]?.userInfo?.firstName || ''}`;
            // navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
        }
        else if (props?.data?.type === "activity updates") {
            buttonState = `new activity updates from ${advisorName && advisorName[0]?.userInfo?.firstName || ''}`;
            // navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
        }
    }


    const handleCardClick = () => {
        console.log(props?.data, "props?.datadsf")

        if (props?.data?.type === "milestone updates") {

            if (props?.data?.milestone?.length > 0) {
                // Extract milestone data
                const milestones = props?.data?.milestone;

                // Get the unique courseId and planId values from the milestone array
                const uniqueCourseIds = [...new Set(milestones.map(milestone => milestone.courseId))];
                const uniquePlanIds = [...new Set(milestones.map(milestone => milestone.planId))];

                // Initialize the payload with default values
                let payload = {
                    "postedBy": props?.data?.id,
                    "updateType": "milestone"
                };
                console.log(uniquePlanIds, "uniquePlanIds");

                // Condition 1: If only one milestone exists, include courseId, milestoneId, and planId
                if (milestones.length === 1) {
                    console.log(uniquePlanIds, "uniquePlanIds");
                    payload.courseId = milestones[0].courseId;
                    payload.milestoneId = milestones[0].milestoneId;
                    payload.planId = milestones[0].planId;
                    props?.viewDashboardCount(payload);
                }
                // Condition 2: If multiple milestones exist with the same courseId and planId, include courseId and planId
                else if (uniqueCourseIds.length === 1 && uniquePlanIds.length === 1) {
                    console.log(uniquePlanIds, "uniquePlanIds");
                    payload.courseId = uniqueCourseIds[0];
                    payload.planId = uniquePlanIds[0];
                    props?.viewDashboardCount(payload);
                }
                // Condition 3: If multiple milestones have different courseIds but the same planId, only include planId
                else if (uniquePlanIds.length === 1) {
                    console.log(uniquePlanIds, "uniquePlanIds");
                    payload.planId = uniquePlanIds[0];
                    props?.viewDashboardCount(payload);
                }

                if (navigatePath) {
                    navigate(navigatePath);
                }
            }

        }

    };

    console.log(advisorName[0]?.userInfo?.fullName, "hellokokdcozkco")
    return (
        <>
            <div className="dashboard-top-parent" onClick={handleCardClick} >
                <Card className="dashCountCard" variant="outlined" >
                    <CardContent>
                        <Typography variant="h6" component="div" className="totalcount"> {props?.data?.count}</Typography>
                        <Typography className="countFrom">{props?.data?.description ? props?.data?.description : buttonState}</Typography>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, studentData, parentData, userProfileData }) => {
    const { isgetusergoals, } = studentData;
    const { parentGoal, } = parentData;
    const { userProfile } = userProfileData;
    const { message, errorList, connectedAdvisors, } = commonData;
    return { message, errorList, connectedAdvisors, isgetusergoals, parentGoal, userProfile };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, getChildProfile, getAdvisorCountUpdateStatus, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus, viewDashboardCount })(Index);
