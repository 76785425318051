import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Button } from '@mui/material';
import { styled } from '@mui/system';
import { GetCurrentTimezone, convertUTCTodateTimeZone } from '../../../utils/CommonUtils';
import "../ScheduleListCard/scheduleList.less"

const StyledCard = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    border: '1px solid #E3E3E3',
    marginBottom: '10px',
    boxShadow: 'none',
    justifyContent: 'start',
    gap:'10px',
});


const BookNowComponent = (props) => {
    let advisorNames = props?.connectedAdvisors?.filter(advisor =>
        props?.data?.userIds?.some(userId => userId === advisor?.userInfo?.id)
      );

    useEffect(()=>{
        if(props?.connectedAdvisors && props?.data){
            console.log("props?.connectedAdvisors",props?.connectedAdvisors);
            console.log("props?.data",props?.data?.userIds)
            
        }
    },[props?.connectedAdvisors])

    const day = convertUTCTodateTimeZone(props?.data?.eventTime, props?.userProfile?.timezone).format('ddd')
    const date = convertUTCTodateTimeZone(props?.data?.eventTime, props?.userProfile?.timezone).format('DD')
    const startTime = convertUTCTodateTimeZone(props?.data?.eventTime, props?.userProfile?.timezone).format('hh:mm A')
    const endTime = convertUTCTodateTimeZone(props?.data?.eventClosingTime, props?.userProfile?.timezone).format('hh:mm A')

    console.log(props?.data?.eventTime, "currentDate",props?.userProfile?.timezone)
    
    return (
        <StyledCard className="scheduledSessions">
            <div style={{display:"flex",flexDirection:"column",padding:10,width:"60px"}}>
               <h6>{day}</h6> 
               <h6 style={{fontSize:"20px"}}>{date}</h6> 
            </div>
            <div style={{display:"flex",flexDirection:"column",borderLeft:"1px solid #ABABAB",paddingLeft:15,gap:"6px"}}>
                <div style={{display:"flex"}}>
                   <h6> {advisorNames[0]?.userInfo?.fullName}</h6>
                </div>
                <div style={{display:"flex"}}>
                  <h6>{startTime} - {endTime} </h6>  
                </div>
            </div>
        </StyledCard>
    );
};

const mapStateToProps = ({ commonData, userProfileData, }) => {
    const { userProfile } = userProfileData;
 
    const { connectedAdvisors } = commonData;
    return { userProfile, connectedAdvisors };
};
export default connect(mapStateToProps, {   })(BookNowComponent);
