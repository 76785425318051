import React, { useEffect } from "react";
import "./reviewSec.less";
import { Grid } from "@mui/material";
import RatingSec from "./RatingSec";
import ReviewSec from "./ReviewSec";
import TotalRating from "./TotalRating";
import { connect } from "react-redux";


const Index = (props) => {
    let {advisorReviewSummary} = props?.detailedUserData?.userInfo?.advisorProfile ?? ""

    return (
        <div className="reviewCard">
            <p className="reviewCard__title">What students says</p>

            <Grid container className="reviewsRatings" spacing={3}>
                <Grid item xs={12} md={6} lg={6} className="ratingSec">
                    <RatingSec reviewSummary={props?.detailedUserData?.userInfo?.advisorProfile?.advisorReviewSummary} review={props?.data}/>
                </Grid>
                <Grid item xs={12} md={6} lg={6} className="totalRating-sec">
                    <TotalRating
                        averageRating={advisorReviewSummary?.averageRating}
                        totalReviews={advisorReviewSummary?.totalNoofReviews}
                        advisorDetail = {props?.detailedUserData?.userInfo}
                        review={props?.reviewData}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12} order={2}>
                    <ReviewSec review={props?.reviewData}  advisorDetail = {props?.detailedUserData?.userInfo}/>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { detailedUserData, reviewData } = commonData;

    return { detailedUserData, reviewData };
};

export default connect(mapStateToProps, {})(Index);
