import React, { useState, useEffect, useCallback } from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../../assets/css/sendInvite.less";
import { connect } from "react-redux";
import { setUserOnboard, setIsUserProfileSuccess } from "../../../../redux/actions";
import { withRouter } from "../../../../redux/store/navigate";
import { EMAIL_LABEL } from "../../../../Constants/commonLabels";
import { SentInviteSpouse, CopyInviteLink, clearMessage, GetInviteSpouse, CopyInviteLinkstatus, SentInviteParentstatus, getUserProfile, setCopyInviteFlag, CopyInviteErrorstatus, SentInviteSpousestatus } from "../../../../../src/redux/actions";
import ReactTooltip from 'react-tooltip';
import CustomAlert from "../../../../components/Alert";
import Loader from "../../../../components/Loader"
import copy from 'copy-to-clipboard';
import AlertBox from '@mui/material/Alert';
import Alert from 'react-bootstrap/Alert';
import Footer from "../../../../componentsNew/Common/Footer";

const Index = (props) => {
  const [fields, setFields] = useState({ email: '' });
  const [errors, setErrors] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [emailMatchs, setEmailMatchs] = useState([]);
  const [isClickedNext, setIsClickedNext] = useState(false);
  const [link, setLink] = useState('');
  const [copiedLink, setCopiedLink] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  useEffect(() => {
    // props.setCopyInviteFlag(true);
    props.clearMessage();
    // props.GetInviteSpouse();
    handleCopySendInvite('componentDidMount');
    setInitialValues();

    if (props.isUserOnboarded) {
      props.navigate('/parent/dashboard');
    }
  }, [props]);

  useEffect(() => {
    if (props.isInviteSentSucess) {
      props.getUserProfile();
      props.SentInviteParentstatus(false);
    }

    if (props.copyInviteFlag && clicked && link === '') {
      handleCopySendInvite('componentDidUpdate');
      props.CopyInviteLinkstatus(false);
    }

    if (props.copyInvitelinkSucess) {
      setLink(props.copyInvitelink);
      setClicked(false);
      props.setCopyInviteFlag(false);
    }

    if (props.copylinkerror && clicked) {
      setAlertVisible(true);
      setCopiedLink(false);
      props.CopyInviteErrorstatus(false);
    }
    if (props?.isSpouse) {
      props?.SentInviteSpousestatus(false)
    }
  }, [props, clicked, link, props?.isSpouse]);

  const handleCopySendInvite = useCallback(
    (vals) => {
      const values = { role: 'parent' };
      if (props.copyInviteFlag) {
        props.CopyInviteLink(values);
      }
    },
    [props]
  );

  const CopyClipboards = useCallback(() => {
    props.clearMessage();
    const textfield = document.createElement('input');
    textfield.setAttribute('id', 'myInput');
    textfield.setAttribute('value', link);
    document.body.appendChild(textfield);
    textfield.select();
    document.execCommand('copy');
    textfield.remove();

    setCopiedLink(true);
    setAlertVisible(true);
    setClicked(true);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      setAlertVisible(false);
      setCopiedLink(false);
    }, 3000);

    props.setCopyInviteFlag(true);
  }, [link, props]);

  useEffect(() => {
    if (props?.userProfile?.parentProfile?.parentEmails) {
      let parentEmails = props.userProfile.parentProfile.parentEmails;
      setFields({ email: parentEmails.join(', ') });
      setSelectedEmail(parentEmails);
    }
  }, [props.userProfile]);

  const handleChange = (field, e) => {
    const value = e.target.value;
    setFields((prevFields) => ({ ...prevFields, [field]: value }));

    if (value.length >= 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    }
    setDisplayError(true);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let updatedErrors = {};

    if (!fields['email']) {
      formIsValid = false;
      updatedErrors['email'] = '*Required';
    }

    if (fields['email']) {
      const lastAtPos = fields['email'].lastIndexOf('@');
      const lastDotPos = fields['email'].lastIndexOf('.');

      if (
        !(lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['email'].length - lastDotPos > 2)
      ) {
        formIsValid = false;
        updatedErrors['email'] = 'Not a valid email';
      }

      if (!validateEmails(fields['email'])) {
        formIsValid = false;
        updatedErrors['email'] = 'Not a valid email';
      }
    }

    setErrors(updatedErrors);
    return formIsValid;
  };

  const validateEmails = (emails) => {
    const re = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
    const emailArray = emails.split(',');
    return emailArray.every((email) => re.test(email.trim()));
  };

  const handleSendInvite = (e) => {
    e?.preventDefault();
    if (handleValidation()) {
      const emailLst = fields['email'].split(',').map((email) => email.trim()).filter((email) => email);
      props.SentInviteSpouse({ emails: emailLst });
      setAlertVisible(true);
      setClicked(true);
      setDisplayError(false);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (displayError) {
      if (props?.userProfile?.parentProfile?.parentEmails?.length === 0 && fields['email'] === '') {
        handleClickSkip();
      } else {
        setIsClickedNext(true);
      }
    } else {
      handleClickSkip();
    }
  };

  const handleClickSkip = () => {
    props.setUserOnboard();
  };

  const setInitialValues = () => {
    const parentEmails = props?.userProfile?.parentEmails || [];
    setFields({ email: parentEmails.join(', ') });
    setSelectedEmail(parentEmails);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="sendInvite sendInviteParent">
        <div className="sendInvite__form">
          <h1 className="sendInvite__form__title text-center">Would you like to invite your spouse?</h1>
          <div className="sendInvite__form_inner">
            <div className="row m-0">
              <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                <div className="input-floating-label">
                  <input
                    type="text"
                    className={"textbox--primary textbox--rounded input"}
                    name="Email"
                    placeholder="Email"
                    value={fields["email"]}
                    onChange={(e) => handleChange("email", e)}
                  />
                  {errors['email'] && <span className="error-text ps-0">{errors['email']}</span>}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                <div className="text-center">
                  <Link to="#">
                    <Button className="btn cta--rounded cta-primary mb-4 w-100" disabled={loading} onClick={handleSendInvite}>
                      {loading && <span className="spinner-border spinner-border-sm"></span>}
                      <span>Send Invite</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <p className="text-center" style={{ cursor: 'pointer' }} onClick={CopyClipboards}>
                Copy invite link
              </p>
              {isClickedNext && <Alert variant="error" onClose={() => setIsClickedNext(false)}>Please click the Send invite button</Alert>}
              {alertVisible && <CustomAlert />}
              {copiedLink && (
                <div className="copyInviteAlert">
                  <Alert variant="success" dismissible onClose={() => setCopiedLink(false)}>Copied to clipboard</Alert>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer">
        <div className="row m-0">
          <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
            <Link to="/onboarding/selectparent" onClick={() => props.setIsUserProfileSuccess(false)}>
              Go back
            </Link>
          </div>
          <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
            <p>8 out of 8 steps</p>
          </div>
          <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
            <Link className="footer__cta" to="#" onClick={handleNext}>Next step</Link>
          </div>
        </div>
      </div> */}

      <Footer currentStep={""} reverseLink={"/onboarding/selectparent"} >
        <div onClick={(e) => handleNext(e)} className="footers__rightSec__cta" >Go to Dashboard
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
          </svg>
        </div>
      </Footer>
    </>
  );
};

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
  const { loading, copyInviteFlag } = commonData
  const { isUserOnboarded, userProfile, isSpouse } = userProfileData;
  const { copyInvitelinkSucess, copyInvitelink, getSpouseList, isInviteSentSucess, copylinkerror, } = userProfileData;
  return { isUserOnboarded, copyInvitelinkSucess, userProfile, copyInvitelink, copyInviteFlag, loading, getSpouseList, isSpouse, isInviteSentSucess, copylinkerror }
};

export default connect(mapStateToProps, { setUserOnboard, setIsUserProfileSuccess, CopyInviteLinkstatus, SentInviteParentstatus, getUserProfile, SentInviteSpouse, setCopyInviteFlag, GetInviteSpouse, CopyInviteLink, clearMessage, CopyInviteErrorstatus, SentInviteSpousestatus })(withRouter(Index));