import React, { Component } from "react";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import "../../../../assets/css/plans.less";
import { connect } from "react-redux";
import { withRouter } from "../../../../redux/store/navigate";
import CustomAlert from "../../../../components/Alert";
import Loader from "../../../../components/Loader";
import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { CreateParentPlan, GetStudentPlanOfParent, getUniversities, EditPlan, setIsChildProfileSuccess, getChildProfile, getChildProfilestatus, getGoalsFromStudent, getInviteAdvisorStatus, getParentGoals, CreatePlanStatus, clearMessage, AdvisorInvite, EditPlanStatus, AdvisorInviteStatus, getInviteAdvisor, AdvisorInviteErrorStatus, GetStudentPlanStatus } from "../../../../redux/actions";
import { Plan_Name, ASSIGN_EXISTING_ADVISOR } from "../../../../Constants/commonLabels";
import { universityApi } from "../../../../utils/AutoComplete";
import CreatableSelect from 'react-select/creatable';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import CancelIcon from '@mui/icons-material/Cancel';
import Footer from "../../../../componentsNew/Common/Footer";


const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <CancelIcon sx={{ fontSize: '26px' }} style={{ "fill": "rgba(34, 34, 34, 0.67)" }} />
        </components.MultiValueRemove>
    );
};

class Index extends Component {
    constructor(props) {
        super(props);
        this.refs = React.createRef();
        this.state = {
            loading: false,
            dataLoaded: false,
            universities: false,
            universityCheckBox: false,
            professionsCheckBox: false,
            gPACheckBox: false,
            fields: { planname: "" },
            errors: {},
            noOptionLength: "",
            selectedUniversityOption: null,
            selectedProfessionOption: null,
            initalPlanAlert: false,
            universityData: [],
            selectedEmail: [],
            alertvisible: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        
        this.props.getChildProfile().then(() => {
            const { childprofile } = this.props;
            let value = {
                searchString: "",
                pageNo: 1,
                limit: 10,
                status: [],
                sortOrder: "latest",
                studentId: childprofile[0]?.id
            };
            this.props.GetStudentPlanOfParent(value);
            this.setInitialValues("Mount");
    
            if (childprofile.length > 0) {
                let getStudentId = { studentId: childprofile[0]?.id };
                this.props.getGoalsFromStudent(getStudentId);
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    
        this.props.clearMessage();
        this.props.getInviteAdvisor();
    }
    
    componentWillReceiveProps(newProps) {
        this.setState({
            errorList: newProps.errorList, listOfUniversities: newProps.universities,
        });
        if (newProps.studentplanstatus) {
            this.setState({
                loading: true, dataLoaded: false
            });

        }
    }

    componentDidUpdate() {
        if (!this.state.universities) {
            this.setState({ loading: false, universities: true })
        }

        this.setInitialValues("Update");


        if (this.props.getchildstatus && this.props.childprofile !== undefined) {
            if (this.props.childprofile.length > 0) {
                let getStudentId = {
                    "studentId": this.props.childprofile[0]?.id
                }
                this.props.getGoalsFromStudent(getStudentId)
            }
            this.props.getChildProfilestatus(false)
        } else if (this.props.getchildstatus && this.props.childprofile.length === 0 || this.props.childprofile.length === undefined) {
            // this.props.getParentGoals()
        }
        if (this.props.isInviteAdvisorerror) {
            this.setState({ alertvisible: true })
            this.props.AdvisorInviteErrorStatus(false)
        }

        if (this.props.iscreateplan) {
            if (this.state.fields.advisorEmails?.length > 0) {
                let emailLst = []
                let advEmailfield
                if (this.state.fields.advisorEmails) {
                    advEmailfield = this.state.fields["advisorEmails"]?.split(',')
                    advEmailfield?.map(element => {
                        if (element.trim() !== "") {
                            emailLst.push(element.trim())
                        }

                    });
                }
                let payload = {
                    emails: emailLst
                }
                this.props.AdvisorInvite(payload)
            }
            else {
                this.props.navigate('/onboarding/intialplans');
            }
            this.props.CreatePlanStatus(false)
        }
        if (this.props.iseditplanphase) {
            if (this.state.fields.advisorEmails?.length > 0) {
                let emailLst = []
                let advEmailfield
                if (this.state.fields.advisorEmails) {
                    advEmailfield = this.state.fields["advisorEmails"]?.split(',')
                    advEmailfield?.map(element => {
                        emailLst.push(element.trim())
                    });
                }
                let payload = {
                    emails: emailLst
                }
                this.props.AdvisorInvite(payload)
            }
            else {
                this.props.navigate('/onboarding/intialplans');
            }
            this.props.EditPlanStatus(false);
        }
        if (this.props.isInviteAdvisor) {
            this.props.navigate('/onboarding/intialplans');
            this.props.AdvisorInviteStatus(false);
            this.props.getInviteAdvisor()
        }
    }

    loadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] })
        }
        this.setState({ isload: true });
        let list = []
        universityApi(value).then((res) => {
            res?.data?.data.map((item, index) => {
                list.push({
                    label: item.university,
                    value: item.university
                });

            });

            this.setState({ universityData: list });
            this.setState({ isload: false });
        })
    }
    handleUniversityText = (e) => {
        let { errors, fields, listOfUniversities } = this.state;
        fields['universityName'] = e;

        this.setState({ fields });
        if (e) {
            this.props.getUniversities({ "searchString": e })
        } else {
            this.setState({ listOfUniversities: [] })
        }
    }
    handleSuggestChange = (value) => {
        let { fields, listOfUniversities } = this.state
        fields['universityName'] = value
        this.setState({ fields, listOfUniversities: [] })
    }
    renderSuggestions = () => {
        let { fields, listOfUniversities } = this.state
        if (listOfUniversities?.length === 0) {
            return null;
        }
        return (
            <ul className="px-1 py-2">
                {listOfUniversities?.map((item) => <li className="w-100 py-1 university-item" style={{ cursor: 'pointer', }} onClick={() => this.handleSuggestChange(item.university, 'universityName')}>{item.university}</li>)}
            </ul>

        );
    }
    onMenuOpen = () => {
        this.setState({ openSuggession: true })
    }
    onMenuClose = () => {
        this.setState({ openSuggession: false })
    }
    filterColors = (inputValue) => {
        let { listOfUniversities } = this.state
        listOfUniversities.filter((e) => e.university.toLowerCase().includes(inputValue.toLowerCase()));
        return listOfUniversities.map(item => ({ 'label': item.university, 'value': item.university }))
    };

    handleFocus = () => {
        const { selectedOption } = this.state;
        if (selectedOption && selectedOption.label) {
            this.setState({ inputValue: selectedOption.label });
        }
    };
    handleCheckboxChange(e, buttonName) {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, errors } = this.state;
        if (buttonName === "universities") {
            universityCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "profession") {
            professionsCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "GPA") {
            gPACheckBox = e.target.checked
            errors["goal"] = ""
        }
        this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, errors })
    }
    handleChange(field, e) {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
            if (this.props.errorList !== undefined && this.props.errorList?.planName !== "" && field === "planname") {
                // this.props.errorList.planName = ""

            }
        }
        this.setState({ fields, errors: errors });
    }
    handleSelectChange(options, name) {
        let { errors } = this.state;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;
        switch (name) {
            case "university":
                selectedUniversityOption = options;
                errors['university'] = ""
                break;
            case "professions":
                if (options.length > 10) {
                    errors['profession'] = "Max upto 10 proffession allowed"
                } else {
                    if (options) {
                        if (options.at(-1)?.value?.length > 50 && options.at(-1)?.__isNew__ === true) {
                            errors['profession'] = "Max value upto 50 character"
                        }
                        else if (options.at(-1)?.value?.length < 50 && options.at(-1)?.__isNew__ === true) {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                        else {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                    }
                }
                break;
        }
        this.setState({ selectedUniversityOption, selectedProfessionOption });
    }
    handleScroll = () => {
        window.scrollTo(0, 400)
    }
    setInitialValues(val) {

        let { universityCheckBox, professionsCheckBox, gPACheckBox, fields, selectedUniversityOption, selectedProfessionOption, universityData } = this.state
        if (this.props?.getstudentplan?.length === 0) {

            if (!this.state?.dataLoaded) {
                if (this.props?.invitedAdvisorList) {

                    let { fields, selectedEmail } = this.state;
                    fields["advisorEmails"] = this.props?.invitedAdvisorList.join(", ");
                    if (this.props?.invitedAdvisorList != undefined) {

                        this.props?.invitedAdvisorList?.map((email) => {
                            selectedEmail.push(email)
                            this.setState({ selectedEmail: selectedEmail })

                        })

                    }
                    // this.setState({ selectedEmail });
                }
                if (this.props?.userProfile && this.props?.userProfile?.parentProfile &&
                    this.props.userProfile?.parentProfile?.goals &&
                    this.props?.parentGoal) {


                    let goalesData = this.props?.parentGoal && this.props?.parentGoal?.goals
                    // if (goalesData && goalesData?.length > 0) {

                    //     goalesData && goalesData?.map((data, key) => {
                    //         if (data == "university") {
                    //             universityCheckBox = false;
                    //         } else if (data == "weighted" && "unweighted") {
                    //             gPACheckBox = false;
                    //         } else if (data == "profession") {
                    //             professionsCheckBox = false;
                    //         }
                    //     })
                    // }
                    // DATA VALUE MAPPING
                    // GPA DATA
                    let userProfileData = this.props?.parentGoal && this.props?.parentGoal;
                    fields["GPA"] = userProfileData?.targetUnweighted ? userProfileData?.targetUnweighted  : "";
                    fields["weighted"] = userProfileData?.targetWeighted? userProfileData?.targetWeighted : "";
                    // University DATA
                    let interestedUniversities = userProfileData?.universities;
                    let universityOptionstions = [];
                    interestedUniversities !== undefined && interestedUniversities?.length > 0 && interestedUniversities.map((university, key) => {
                        var temp = { label: university, value: university }
                        universityOptionstions.push(temp);
                    });
                    selectedUniversityOption = universityOptionstions;
                    // Profession DATA
                    let interestedProfession = userProfileData?.interests || userProfileData?.professions;
                    let professionOptionstions = [];
                    interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                        var temp = { label: profession, value: profession }
                        professionOptionstions.push(temp);
                    });
                    selectedProfessionOption = professionOptionstions;
                    // DATA VALUE MAPPING
                    this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption, universityData });
                    this.setState({ dataLoaded: true, loading: false })
                }
            }
        }
        if (this.props?.getstudentplan?.length > 0) {

            let userProfile = this.props?.getstudentplan && this.props.getstudentplan?.length > 0 && this.props?.getstudentplan[0]?.planInfo;
            let goalesData = userProfile && userProfile?.goals
            if (userProfile && userProfile?.goals) {

                let interestedUniversities = userProfile && userProfile.universities;
                let universityOptionstions = [];
                console.log(interestedUniversities, val, "interestedUniversities");
                console.log(userProfile.universities, "userProfile");
                userProfile && userProfile.universities !== undefined && userProfile.universities.length > 0 && userProfile.universities.map((university, key) => {
                    var temp = { label: university, value: university }
                    universityOptionstions.push(temp);
                });
                selectedUniversityOption = universityOptionstions;

                if (!this.state.dataLoaded) {
                    console.log("dataLoaded called");

                    if (this.props.invitedAdvisorList) {
                        let { fields, selectedEmail } = this.state;
                        fields["advisorEmails"] = this.props?.invitedAdvisorList.join(", ");
                        if (this.props?.invitedAdvisorList != undefined) {
                            this.props?.invitedAdvisorList?.map((email) => {
                                selectedEmail.push(email)
                                this.setState({ selectedEmail: selectedEmail })
                            })
                        }
                        // this.setState({ selectedEmail });

                    }

                    if (goalesData && goalesData?.length > 0) {
                        goalesData && goalesData?.map((data, key) => {
                            if (data == "university") {
                                universityCheckBox = true;
                                // this.refs.universityCheckBox.checked = true;
                            } else if (data === "gpa") {
                                gPACheckBox = true;
                                // this.refs.gPACheckBox.checked = true;
                            } else if (data == "profession") {
                                professionsCheckBox = true;
                                // this.refs.professionsCheckBox.checked = true;
                            }
                        })
                    }
                    // let userProfileData = this.props.getstudentplan && this.props.getstudentplan?.length > 0 && this.props.getstudentplan[0]
                    fields["planname"] = userProfile?.planName ? userProfile?.planName : "";
                    fields["GPA"] = userProfile.unweighted? userProfile.unweighted : "";
                    fields["weighted"] = userProfile?.weighted ? userProfile?.weighted : "";
                    // University Data

                    // Interset Data
                    let interestedProfession = userProfile && userProfile.professions
                    let professionOptionstions = [];
                    interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                        var temp = { label: profession, value: profession }
                        professionOptionstions.push(temp);
                    });
                    selectedProfessionOption = professionOptionstions;

                    this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption });
                    this.setState({ dataLoaded: true, loading: false })
                    this.props.GetStudentPlanStatus(false)
                }
            }
        }
        this.props.getInviteAdvisorStatus(false)

    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;

        if (fields["planname"] === "") {
            formIsValid = false;
            errors["planname"] = "*Required";
        }
        if (fields["planname"]?.length > 50) {
            formIsValid = false;
            errors["planname"] = "Plan name cannot exceed maximum of 50 characters";
        }
        // if (fields["planname"] !== "") {
        //     const textcaseRegExp = /^[A-Za-z0-9 ]+$/;
        //     const emailvalid = textcaseRegExp.test(fields["planname"]);
        //     if (!emailvalid) {
        //         formIsValid = false;
        //         errors["planname"] = "No special character allowed";
        //     }
        // }
        let emailLst = []
        let emailfield

        if (fields["advisorEmails"] !== "") {

            emailfield = fields["advisorEmails"]?.split(',')
            emailfield?.map(element => {
                emailLst.push(element.trim())
            });
        }

        if (fields["advisorEmails"] && fields["advisorEmails"] !== "") {
            let emailLst = []
            let emailfield = fields["advisorEmails"]?.split(',')
            emailfield?.map(element => {
                if (element !== "") {
                    emailLst.push(element.trim())
                } else {
                    formIsValid = false;
                    errors["advisorEmails"] = "Invalid Email";
                }
            });
        }

        emailLst.map(email => {
            if (email !== "") {
                const textcaseRegExp = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
                const emailvalid = textcaseRegExp.test(email);
                if (!emailvalid) {
                    formIsValid = false;
                    errors["advisorEmails"] = "Invalid Email";
                }
                let lastAtPos = email?.lastIndexOf("@");
                let lastDotPos = email?.lastIndexOf(".");
                if (
                    !(
                        lastAtPos < lastDotPos &&
                        lastAtPos > 0 &&
                        email.indexOf("@@") === -1 &&
                        lastDotPos > 2 &&
                        email.length - lastDotPos > 2
                    )
                ) {
                    formIsValid = false;
                    errors["advisorEmails"] = "Invalid Email";
                }
            }
        })

        if (!this.state.universityCheckBox && !this.state.professionsCheckBox && !this.state.gPACheckBox) {
            formIsValid = false;
            errors["goal"] = "Please select any goal";
        }
        if (this.state.universityCheckBox) {
            if (selectedUniversityOption === null) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }
            else if (selectedUniversityOption.length === 0) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }

        }
        if (this.state.professionsCheckBox) {
            if (selectedProfessionOption === null) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }
            else if (selectedProfessionOption.length === 0) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }

        }
        if (this.state.gPACheckBox) {
            if (fields.GPA === "") {
                formIsValid = false;
                errors["GPA"] = "*Required";
            }
            if (fields.weighted === "") {
                formIsValid = false;
                errors["weighted"] = "*Required";
            }
            if (fields.GPA !== "") {
                const decimalcaseRegExp = /^(?:[1-3](\.\d{1})?|4(\.0{1})?)$/;
                const digitcaseRegExp = /^[0-4]$/;
                const floatcasenumber = decimalcaseRegExp.test(fields["GPA"]);
                const digitcasenumber = digitcaseRegExp.test(fields["GPA"]);
                if (!digitcasenumber && !floatcasenumber) {
                    formIsValid = false;
                    errors["GPA"] = "Invalid unweighted GPA";
                }
            }
            if (fields.weighted !== "") {
                const decimalcaseRegExp = /^(?:[1-4](\.\d{1})?|5(\.0{1})?)$/;
                const digitcaseRegExp = /^[0-5]$/;
                const floatcasenumber = decimalcaseRegExp.test(fields["weighted"]);
                const digitcasenumber = digitcaseRegExp.test(fields["weighted"]);
                if (!digitcasenumber && !floatcasenumber) {
                    formIsValid = false;
                    errors["weighted"] = "Invalid weighted GPA";
                }
            }
        }
        // if (this.state.gPACheckBox && fields["GPA"] === undefined) {
        //     formIsValid = false;
        //     errors["GPA"] = "Please enter GPA";
        // }
        // if (this.state.gPACheckBox && typeof fields["GPA"] !== "undefined") {
        //     const decimalcaseRegExp = (/^[1-9]\.\d{1,2}$/);
        //     const digitcaseRegExp = (/^[1-9]$/);
        //     const floatcasenumber = decimalcaseRegExp.test(fields["GPA"]);
        //     const digitcasenumber = digitcaseRegExp.test(fields["GPA"]);
        //     if (!digitcasenumber && !floatcasenumber) {
        //         formIsValid = false;
        //         errors["GPA"] = "Invalid GPA";
        //     }
        // }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleNext = async (e) => {
        this.setState({ initalPlanAlert: true })
        this.setState({ alertvisible: false })
        let userProfile = this.props.getstudentplan && this.props.getstudentplan[0]?.planInfo;
        let planId = userProfile && userProfile.id
        if (planId === undefined) {
            if (this.handleValidation()) {
                let goals = [];
                let fields = this.state.fields;
                let { selectedUniversityOption, selectedProfessionOption } = this.state;
                let gpaValue = fields["GPA"];
                let Unweightedgpa = fields["weighted"];
                if (this.state.universityCheckBox)
                    goals.push("university");
                else
                    selectedUniversityOption = [];

                if (this.state.professionsCheckBox)
                    goals.push("profession");
                else
                    selectedProfessionOption = [];
                if (this.state.gPACheckBox)
                    goals.push("GPA");
                let selectedUniversity = [];
                selectedUniversityOption.forEach(element => {
                    selectedUniversity.push(element.label || element);
                });
                let selectedProfession = [];
                selectedProfessionOption.forEach(element => {
                    selectedProfession.push(element.label);
                });

                let emailLst = []
                let advEmailfield
                if (fields.advisorEmails) {
                    advEmailfield = fields["advisorEmails"]?.split(',')
                    advEmailfield?.map(element => {
                        emailLst.push(element.trim())
                    });
                }

                let values = {
                    planName: fields.planname,
                    specificUniversity: this.state.universityCheckBox,
                    specificProfession: this.state.professionsCheckBox,
                    improveGpa: this.state.gPACheckBox,
                    plannedUniversities: selectedUniversity,
                    plannedProfessions: selectedProfession,
                    advisorId: null,
                    studentId: this.props?.childprofile && this.props?.childprofile[0]?.id,
                    accessibleAdvisors: []
                    // studentsEmailList: emailLst
                }
                if (values.improveGpa === true) {
                    values["weighted"] = Unweightedgpa
                    values["unweighted"] = gpaValue
                }
                if (values.improveGpa === false) {
                    values["desiredGpa"] = null
                }
                this.props.CreateParentPlan(values)
            }
        }
        if (planId !== undefined) {
            if (this.handleValidation()) {
                let goals = [];
                let fields = this.state.fields;
                let { selectedUniversityOption, selectedProfessionOption } = this.state;
                let gpaValue = fields["GPA"];
                let Unweightedgpa = fields["weighted"];
                if (this.state.universityCheckBox)
                    goals.push("university");
                else
                    selectedUniversityOption = [];

                if (this.state.professionsCheckBox)
                    goals.push("profession");
                else
                    selectedProfessionOption = [];

                if (this.state.gPACheckBox)
                    goals.push("GPA");
                let selectedUniversity = [];
                selectedUniversityOption.forEach(element => {
                    selectedUniversity.push(element.label || element);
                });
                let selectedProfession = [];
                selectedProfessionOption.forEach(element => {
                    selectedProfession.push(element.label);
                });



                let values = {
                    planName: fields.planname,
                    specificUniversity: this.state.universityCheckBox,
                    specificProfession: this.state.professionsCheckBox,
                    improveGpa: this.state.gPACheckBox,
                    plannedUniversities: selectedUniversity,
                    plannedProfessions: selectedProfession,
                    advisorId: null,
                    studentId: this.props?.childprofile && this.props?.childprofile[0]?.id,
                    accessibleAdvisors: []

                }
                if (values.improveGpa === true) {
                    values["weighted"] = Unweightedgpa
                    values["unweighted"] = gpaValue
                }
                if (values.improveGpa === false) {
                    values["desiredGpa"] = null
                }
                this.props.EditPlan(values, planId)
            }
        }
    }

    loadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.university,
                            value: item.university
                        });

                    });
                    this.setState({ universityData: list });
                } else {
                    this.setState({ universityData: [] });
                }

                this.setState({ isload: false });
            })
        }

    }


    onTagsChange = (event, values) => {
        let { errors } = this.state;
        this.setState({ selectedUniversityOption: values });
        errors['university'] = ""
    }

    render() {
        const { universities, professions } = this.props;
        let { universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption } = this.state;
        let universityOptionstions = [];
        let professionOptionstions = [];
        universities !== undefined && universities.length > 0 && universities.map((university, key) => {
            var temp = { label: university.university, value: university.university }
            universityOptionstions.push(temp);

        });
        professions !== undefined && professions.length > 0 && professions.map((profession, key) => {
            var temp = { label: profession.profession, value: profession.profession }
            professionOptionstions.push(temp);
        });



        let isGetUserGoals = this.props?.parentGoal?.goals
        let userProfile = this.props.getstudentplan && this.props.getstudentplan?.length > 0 && this.props.getstudentplan[0]?.planInfo && this.props.getstudentplan[0]?.planInfo?.goals


        const customClearIconStyle = {
            color: 'red', // You can customize the color here
            fontSize: '14px', // You can adjust the font size here
            '&:hover': {
              color: 'darkred', // You can customize the hover color here
            },
          };

        return (
            <>
            {console.log("CheckBox - universityCheckBox",universityCheckBox)}
            {console.log("CheckBox - professionsCheckBox",professionsCheckBox)}
            {console.log("CheckBox - gPACheckBox",gPACheckBox)}
                {this.state.loading && <Loader />}
                {this.state.loading || this.props.loading && <Loader />}
                <div className="role">
                    <div className="role_card" style={{ width: "430px !important", marginBottom: "100px" }}>

                        <h1 className="role_title text-center planTitleParent">Setup initial plan for {this.props?.childprofile.length > 0 ? this.props?.childprofile[0]?.firstName + " " + this.props?.childprofile[0]?.lastName : ""}</h1>
                        <Form className="login_card_form" autoComplete="off" >
                            <div className="goals__form">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-floating-label">
                                            <input refs="planname" type="text" className={"textbox--primary textbox--rounded input"} name="planname"
                                                placeholder="planname" onChange={this.handleChange.bind(this, "planname")} value={this.state.fields["planname"]} />
                                            <label>{Plan_Name}</label>
                                            {this?.state?.errorList && this?.state?.errorList["planName"] && <span className="error-text">{this.state.errorList["planName"]}</span>}
                                            {this.state.errors["planname"] && <span className="error-text">{this.state.errors["planname"]}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <div className="text-center">
                            <p className="profile__form__sub-title_new text-wrapper" >Select one or more Goals for this Plan</p>
                            <p className="planSubpara">Later you can create multiple plans with different sets of courses and activities for different goals</p>
                        </div>
                        <div className="goals_page">
                            {isGetUserGoals?.includes("university") ?
                                isGetUserGoals?.map((val) => {
                                    return (val === "university" ?
                                        <div className="mb-2 role__btns">
                                            <input type="checkbox" ref='universityCheckBox' defaultChecked={universityCheckBox} checked={universityCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                                        </div>
                                        : <></>)
                                }) : userProfile && userProfile?.map((val) => {
                                    return (val === "university" ?
                                        <div className="mb-2 role__btns">
                                            <input type="checkbox" ref='universityCheckBox' defaultChecked={universityCheckBox} checked={universityCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                                        </div>
                                        : <></>)
                                })
                            }

                            {isGetUserGoals?.includes("profession") ?
                                isGetUserGoals?.map((val) => {
                                    return (val === "profession" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='professionsCheckBox' defaultChecked={professionsCheckBox} checked={professionsCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                                        </div>
                                        : <></>)
                                }) : userProfile && userProfile.map((val) => {
                                    return (val === "profession" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='professionsCheckBox' defaultChecked={professionsCheckBox} checked={professionsCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                                        </div>
                                        : <></>)
                                })

                            }

                            {userProfile && userProfile?.includes("gpa") ?
                                userProfile && userProfile?.map((val) => {
                                    return (val === "gpa" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='gPACheckBox' defaultChecked={gPACheckBox} checked={gPACheckBox} onClick={(e) => this.handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                                        </div>
                                        : <></>)
                                })
                                : isGetUserGoals?.map((val) => {
                                    return (val === "weighted" && "unweighted" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='gPACheckBox' defaultChecked={gPACheckBox} checked={gPACheckBox} onClick={(e) => this.handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                                        </div>
                                        : <></>)
                                })

                            }
                        </div>
                        {this.state.errors["goal"] && <span className="error-text text-center" style={{ display: "table", margin: "0 auto" }}>{this.state.errors["goal"]}</span>}
                        <Form className="login_card_form mb-3" autoComplete="off">
                            <div className="goals__form">
                                {universityCheckBox &&
                                    <div className="row">
                                        <div className="col-md-12 mb-5 role__btns asyncmultiselect multinew">
                                            <Autocomplete
                                                multiple
                                                // loading={this.state.isload}
                                                //   noOptionsText={'There is no university related to your search'}
                                                className="goals__form__select"
                                                id="tags-filled"
                                                name="universityDropdown"
                                                type='text'
                                                clearOnBlur={false}
                                                freeSolo={true}
                                                openOnFocus={true}
                                                filterSelectedOptions
                                                clearIcon
                                                ChipProps
                                                forcePopupIcon={false}
                                               
                                                options={this.state.universityData?.length > 0 ? this.state.universityData.map((option) => option.label) : []}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip label={option} {...getTagProps({ index })}  deleteIcon={<CancelIcon  sx={{fontSize :'20px !important'}}/>} />
                                                    ))
                                                }
                                                filterOptions={(options, state) => options}
                                                defaultValue={selectedUniversityOption !== null ? selectedUniversityOption?.map((option) => option.label || option) : []}
                                                onChange={this.onTagsChange}
                                                onInputChange={async (event, value) => {
                                                    this.loadOptions(value)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Desired University"
                                                        size="small"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onKeyDown: (e) => {
                                                                if (e.key === 'Enter') {
                                                                    // e.stopPropagation();
                                                                    e.preventDefault();
                                                                }
                                                            },
                                                        }}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    borderColor: 'lightgrey',
                                                                },
                                                            }
                                                        }}
                                                    />

                                                )}
                                            />
                                            {this.state.errors["university"] && <span className="error-text">{this.state.errors["university"]}</span>}
                                        </div>
                                    </div>
                                }
                                {professionsCheckBox &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 mb-5 role__btns asyncmultiselect">
                                                <CreatableSelect className="goals__form__select" noOptionsMessage={() => null} classNamePrefix="mySelect" options={professionOptionstions} id="professionsSelect"
                                                    closeMenuOnSelect={true} isClearable={false} isMulti components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove }}
                                                    placeholder="Professions" value={selectedProfessionOption} onChange={(value) => this.handleSelectChange(value, 'professions')}
                                                    styles={{
                                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                    }} />
                                                {this.state.errors["profession"] && <span className="error-text">{this.state.errors["profession"]}</span>}
                                            </div>
                                        </div>
                                    </>
                                }
                                {gPACheckBox &&
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="input-floating-label">
                                                <input refs="GPA" type="text" className={"textbox--primary textbox--rounded input"} name="GPA"
                                                    placeholder="GPA" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "GPA")} value={this.state.fields["GPA"]} />
                                                <label>Target Unweighted GPA</label>
                                                {this?.state?.errorList && this?.state?.errorList["unweighted"] && <span className="error-text">{this.state.errorList["unweighted"]}</span>}
                                                {this.state.errors["GPA"] && <span className="error-text">{this.state.errors["GPA"]}</span>}

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12" style={{ "padding": "0px" }}>
                                            <div className="input-floating-label">
                                                <input refs="weighted" type="text" className={"textbox--primary textbox--rounded input"} name="weighted"
                                                    placeholder="weighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "weighted")} value={this.state.fields["weighted"]} />
                                                <label>Target Weighted GPA</label>
                                                {this?.state?.errorList && this?.state?.errorList["weighted"] && <span className="error-text">{this.state.errorList["weighted"]}</span>}
                                                {this.state.errors["weighted"] && <span className="error-text">{this.state.errors["weighted"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    {/* <div className="col-md-12 mb-2">
                                        <div className="input-floating-label">
                                            <input refs="advisorEmails" type="text" className={"planEmail textbox--primary textbox--rounded input goals__form__select"} value={this.state.fields["advisorEmails"]} name="advisorEmails" placeholder=" "
                                                onChange={this.handleChange.bind(this, "advisorEmails")} style={{ width: "100%", fontSize: '13px' }} />
                                            <label>{ASSIGN_EXISTING_ADVISOR}</label>
                                            {this.state.errors["advisorEmails"] && <span className="error-text">{this.state.errors["advisorEmails"]}</span>}
                                            <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>

                                        </div>
                                    </div> */}
                                    {this.state.alertvisible &&
                                        <CustomAlert />
                                    }
                                </div>
                            </div>
                        </Form>
                    </div>

                </div>
                {/* <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/onboarding/profile/child" className="footer__left__cta" >
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" /> </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center ">
                            <p>4 out of 8 steps</p>
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center plansFooter justify-content-center">
                            <Link className="skipButton pe-3" to="/onboarding/selectadvisor/child"> Skip </Link>
                            <Link className="footer__cta_plan" to="#" onClick={(e) => this.handleNext(e)}>Next step</Link>
                        </div>
                    </div>
                </div> */}

                <Footer  reverseLink={"/onboarding/profile/child"}>
                    <Link className="footers__rightSec__cta" to="#" onClick={(e) => this.handleNext(e)} >Add courses and activities
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" stroke-width="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
            </>
        );
    }
}

const mapStateToProps = ({ userProfileData, studentData, parentData, commonData }) => {
    const { message, errorList, loading, universities, professions, } = commonData
    const { userProfile, childprofile, getchildstatus } = userProfileData;
    const { iscreateplan, getstudentplan, iseditplanphase, isInviteAdvisor, invitedAdvisorList, invitedAdvisorListstatus, isInviteAdvisorerror, studentplanstatus } = studentData;
    const { isParentGoalSuccess, isParentGoalStatus, parentGoal } = parentData;
    return { studentplanstatus, message, errorList, childprofile, userProfile, userProfileData, loading, getchildstatus, iscreateplan, getstudentplan, universities, professions, iseditplanphase, isInviteAdvisor, isParentGoalSuccess, isParentGoalStatus, parentGoal, invitedAdvisorList, invitedAdvisorListstatus, isInviteAdvisorerror }
};

export default connect(mapStateToProps, { GetStudentPlanStatus, getUniversities, CreateParentPlan, getGoalsFromStudent, getChildProfile, getChildProfilestatus, GetStudentPlanOfParent, EditPlan, getInviteAdvisorStatus, CreatePlanStatus, setIsChildProfileSuccess, getParentGoals, clearMessage, AdvisorInvite, EditPlanStatus, AdvisorInviteStatus, getInviteAdvisor, AdvisorInviteErrorStatus })(withRouter(Index));